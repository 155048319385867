import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import { Card, Typography, Button, Avatar, Tooltip, List, Tag } from 'antd';
import Globe from 'globe.gl';
import moment from 'moment-timezone';
import axios from '../api/axiosConfig';
import './Home.css';
import { WiDaySunny, WiCloudy, WiRain, WiStrongWind, WiSnow, WiThunderstorm, WiDust } from 'react-icons/wi';
import ReactDOMServer from 'react-dom/server';
import { FaChartLine, FaCalendarAlt, FaUsers, FaCogs, FaPalette, FaUserPlus, FaPlay, FaPause } from 'react-icons/fa';
import confetti from 'canvas-confetti';

const { Title, Paragraph, Text } = Typography;

function Home({ user }) {
  const globeEl = useRef();
  const globeInstanceRef = useRef(null);
  const [teamBriefing, setTeamBriefing] = useState(null);
  const [userBriefing, setUserBriefing] = useState(null);
  const [teamBriefingType, setTeamBriefingType] = useState('morning');
  const [userBriefingType, setUserBriefingType] = useState('morning');
  const [isRotating, setIsRotating] = useState(true);
  const [users, setUsers] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [userColors, setUserColors] = useState({});
  const [weatherData, setWeatherData] = useState({});
  const birthdayCardRef = useRef(null);
  const [upcomingTasks, setUpcomingTasks] = useState([]);

  // Updated resources data
  const resources = [
    { title: 'Sales', url: 'https://www.notion.so/Salg-c546324d6b7b4c138818a5b59051d5c2?pvs=25', icon: 'chart-line' },
    { title: 'Meeting Scheduling', url: 'https://www.notion.so/M-debooking-36b6ea9e26714641887d3f6bdc197640', icon: 'calendar-alt' },
    { title: 'Customer Success', url: 'https://www.notion.so/Customer-Succes-126110f44f29481596a9e5886bf8ef28', icon: 'users' },
    { title: 'Operations', url: 'https://www.notion.so/Operations-191749bb9ecf4a4fad37f70eff35857b', icon: 'cogs' },
    { title: 'Brand Guidelines', url: 'https://www.notion.so/Brand-Guidelines-6d487abcfb754d0198be6cd45fb0303c', icon: 'palette' },
    { title: 'Employee Onboarding', url: 'https://www.notion.so/Medarbejder-Onboarding-aedead79ae0642c6af2e177ca008c382', icon: 'user-plus' },
  ];

  const getResourceIcon = (iconName) => {
    switch (iconName) {
      case 'chart-line':
        return <FaChartLine />;
      case 'calendar-alt':
        return <FaCalendarAlt />;
      case 'users':
        return <FaUsers />;
      case 'cogs':
        return <FaCogs />;
      case 'palette':
        return <FaPalette />;
      case 'user-plus':
        return <FaUserPlus />;
      default:
        return <FaChartLine />;
    }
  };

  useEffect(() => {
    console.log("Home component mounted. User:", user);
    if (user) {
      fetchBriefings();
    } else {
      console.log("User not available yet");
    }
  }, [user]);

  useEffect(() => {
    fetchUsers();
    fetchUpcomingTasks();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/user');
      setUsers(response.data);
      setOnlineUsers(response.data.filter(user => 
        user.status === 'online' || user.status === 'inactive'
      ));
      const colors = {};
      response.data.forEach(user => {
        colors[user.id] = user.settings?.avatar_color || '#f56a00';
      });
      setUserColors(colors);
      fetchWeatherData(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchWeatherData = async (users) => {
    try {
      const weatherResponse = await axios.get('/user/weather', { params: { user_ids: users.map(user => user.id) } });
      setWeatherData(weatherResponse.data);
    } catch (error) {
      console.error('Error fetching weather data:', error);
    }
  };

  const fetchUpcomingTasks = async () => {
    try {
      const response = await axios.get('/work/upcoming_tasks');
      setUpcomingTasks(response.data);
    } catch (error) {
      console.error('Error fetching upcoming tasks:', error);
    }
  };

  const fetchBriefings = async () => {
    try {
      console.log('Fetching team briefing');
      const teamResponse = await axios.get('/ai/get_briefing?target=team');
      console.log('Team briefing response:', teamResponse.data);
      setTeamBriefing(teamResponse.data.content);
      setTeamBriefingType(teamResponse.data.type);
  
      if (user && user.id) {
        console.log(`Fetching user briefing for user ID: ${user.id}`);
        const userResponse = await axios.get(`/ai/get_briefing?target=user&user_id=${user.id}`);
        console.log('User briefing response:', userResponse.data);
        setUserBriefing(userResponse.data.content);
        setUserBriefingType(userResponse.data.type);
      } else {
        console.log('User ID not available, skipping user briefing fetch');
        setUserBriefing(null);
        setUserBriefingType(null);
      }
    } catch (error) {
      console.error('Error fetching briefings:', error);
      setTeamBriefing(null);
      setUserBriefing(null);
      setTeamBriefingType(null);
      setUserBriefingType(null);
    }
  };

  useLayoutEffect(() => {
    if (!globeEl.current) return;

    const globe = Globe()
      .globeImageUrl('//unpkg.com/three-globe/example/img/earth-blue-marble.jpg')
      .bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png')
      .backgroundColor('rgba(0,0,0,0)') // Set background to transparent
      .width(globeEl.current.clientWidth)
      .height(globeEl.current.clientHeight);

    globe(globeEl.current);

    const resizeGlobe = () => {
      globe.width(globeEl.current.clientWidth);
      globe.height(globeEl.current.clientHeight);
    };

    window.addEventListener('resize', resizeGlobe);

    globe.controls().autoRotate = true;
    globe.controls().autoRotateSpeed = 0.5;
    globe.pointOfView({ lat: 20, lng: 45, altitude: 1.5 });

    globe.camera().zoom = 1;
    globe.camera().updateProjectionMatrix();
    globe.controls().minDistance = 101;
    globe.controls().maxDistance = 500;

    globeInstanceRef.current = globe;

    return () => {
      window.removeEventListener('resize', resizeGlobe);
      globe.controls().dispose();
      globe.renderer().dispose();
    };
  }, []);

  const getWeatherIcon = (iconCode) => {
    switch (iconCode) {
      case '01d':
      case '01n':
        return <WiDaySunny />;
      case '02d':
      case '02n':
      case '03d':
      case '03n':
      case '04d':
      case '04n':
        return <WiCloudy />;
      case '09d':
      case '09n':
      case '10d':
      case '10n':
        return <WiRain />;
      case '11d':
      case '11n':
        return <WiThunderstorm />;
      case '13d':
      case '13n':
        return <WiSnow />;
      case '50d':
      case '50n':
        return <WiDust />;
      case '23d':
      case '23n':
        return <WiStrongWind />;
      default:
        return <WiDaySunny />;
    }
  };

  const getGlobeTooltipContent = useCallback((user) => {
    return `
      <div class="globe-tooltip">
        <b>${user.name} ${user.surname}</b><br />
        ${user.location_info?.city}, ${user.location_info?.country}<br />
        Local time: ${moment().tz(user.location_info?.timezone || 'UTC').format('HH:mm')}<br />
        ${weatherData[user.id] ? `
          ${ReactDOMServer.renderToString(getWeatherIcon(weatherData[user.id].icon))} ${weatherData[user.id].temperature}°C ${weatherData[user.id].description}
        ` : 'Loading weather data...'}
      </div>
    `;
  }, [weatherData]);

  useEffect(() => {
    const globe = globeInstanceRef.current;
    if (!globe || !users.length) return;

    globe
      .pointsData(users)
      .pointLat(d => d.location_info?.latitude || 0)
      .pointLng(d => d.location_info?.longitude || 0)
      .pointColor(d => userColors[d.id] || '#FFFFFF')
      .pointAltitude(0.03)
      .pointRadius(0.5)
      .pointLabel(getGlobeTooltipContent);

    globe.controls().autoRotate = isRotating;
  }, [users, isRotating, userColors, getGlobeTooltipContent]);

  const getUserTooltipContent = useCallback((user) => {
    return (
      <div>
        <div>{getDisplayName(user)}</div>
        <div>{user.email}</div>
        <div>{getLocation(user.location_info)}</div>
        <div>{getLocalTime(user.location_info)}</div>
        {weatherData[user.id] ? (
          <div className="user-tooltip-weather">
            {getWeatherIcon(weatherData[user.id].icon)} {weatherData[user.id].temperature}°C {weatherData[user.id].description}
          </div>
        ) : (
          <div>Loading weather data...</div>
        )}
      </div>
    );
  }, [weatherData]);

  const toggleRotation = () => {
    setIsRotating(!isRotating);
  };

  const getLocalTime = (locationInfo) => {
    if (!locationInfo || !locationInfo.timezone) return 'Unknown';
    return moment().tz(locationInfo.timezone).format('HH:mm');
  };

  const getLocation = (locationInfo) => {
    if (!locationInfo) return 'Unknown';
    return `${locationInfo.city}, ${locationInfo.country}`;
  };

  const getInitials = (name, surname) => {
    if (!name && !surname) return '?';
    return `${name ? name[0] : ''}${surname ? surname[0] : ''}`.toUpperCase();
  };

  const getDisplayName = (user) => {
    return `${user.name || ''} ${user.surname || ''}`.trim() || user.email || 'Unknown User';
  };

  const getUpcomingBirthdays = () => {
    const today = moment().startOf('day');
    console.log('Today:', today.format('YYYY-MM-DD'));
    return users
      .filter(user => user.birthday)
      .map(user => {
        const birthday = moment(user.birthday).startOf('day');
        let nextBirthday = moment(birthday).year(today.year());
        if (nextBirthday.isBefore(today)) {
          nextBirthday = nextBirthday.add(1, 'year');
        }
        const daysUntilBirthday = nextBirthday.diff(today, 'days');
        console.log(`User: ${user.name}, Birthday: ${birthday.format('YYYY-MM-DD')}, Next Birthday: ${nextBirthday.format('YYYY-MM-DD')}, Days Until: ${daysUntilBirthday}`);
        return {
          ...user,
          nextBirthday,
          daysUntilBirthday
        };
      })
      .sort((a, b) => a.daysUntilBirthday - b.daysUntilBirthday)
      .slice(0, 3);
  };

  const triggerBirthdayConfetti = useCallback((element) => {
    console.log('Confetti function called');
    const rect = element.getBoundingClientRect();
    const x = (rect.left + rect.width / 2) / window.innerWidth;
    const y = rect.top / window.innerHeight;
  
    const duration = 5 * 1000; // Reduced duration to 5 seconds
    const animationEnd = Date.now() + duration;
  
    const randomInRange = (min, max) => Math.random() * (max - min) + min;
  
    (function frame() {
      const timeLeft = animationEnd - Date.now();
  
      confetti({
        particleCount: 5,
        startVelocity: 15, // Reduced velocity
        spread: 60, // Reduced spread
        origin: { x, y: y + 0.2 },
        colors: ['#ffd700', '#ff0000', '#00ff00', '#0000ff', '#ff00ff'],
        ticks: Math.max(50, 200 * (timeLeft / duration)), // Reduced ticks for faster fading
        shapes: ['square', 'circle'],
        gravity: 1.2, // Increased gravity to make particles fall faster
        scalar: randomInRange(0.4, 0.6), // Reduced scalar for smaller particles
        drift: randomInRange(-0.2, 0.2), // Reduced drift
        decay: 0.94, // Added decay for faster fading
      });
  
      if (timeLeft > 0) {
        requestAnimationFrame(frame);
      }
    }());
  }, []);

  const renderBirthdayItem = (user) => {
    const isBirthdayToday = user.daysUntilBirthday === 0;
    const isUpcoming = user.daysUntilBirthday <= 3;

    let itemClass = 'birthday-item';
    if (isBirthdayToday) itemClass += ' birthday-today';
    else if (isUpcoming) itemClass += ' birthday-upcoming';

    return (
      <List.Item 
        key={user.id} 
        className={itemClass}
        ref={isBirthdayToday ? birthdayCardRef : null}
      >
        <List.Item.Meta
          title={
            <span>
              {user.name} {user.surname}
              {isBirthdayToday && (
                <Tag color="gold" className="birthday-tag">
                  Today!
                </Tag>
              )}
            </span>
          }
          description={isBirthdayToday ? null : `${user.nextBirthday.format('MMM D')}${user.daysUntilBirthday === 1 ? ' (Tomorrow)' : ` (in ${user.daysUntilBirthday} days)`}`}
        />
      </List.Item>
    );
  };

  useEffect(() => {
    const birthdaysToday = getUpcomingBirthdays().filter(user => user.daysUntilBirthday === 0);

    if (birthdaysToday.length > 0) {
      console.log('Birthdays today:', birthdaysToday);
      if (birthdayCardRef.current) {
        console.log('Triggering confetti');
        triggerBirthdayConfetti(birthdayCardRef.current);
      } else {
        console.log('Birthday card ref not found');
      }
    }
  }, [users, triggerBirthdayConfetti]);

  const formatBriefingContent = (content) => {
    if (!content) return null;
    
    // Replace **text** with <strong>text</strong>
    return content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  };

  const getGreeting = () => {
    const currentHour = moment().hour();
    return currentHour < 12 ? 'morning' : 'afternoon';
  };

  return (
    <div className="home-content">
      <Title level={2} className="home-title">Good {getGreeting()}, Rasmus</Title>
      
      <div className="main-container">
        <Card title="Your upcoming tasks" className="events-card">
          <List
            itemLayout="horizontal"
            dataSource={upcomingTasks}
            renderItem={task => (
              <List.Item>
                <List.Item.Meta
                  title={task.description}
                  description={
                    <div className="task-info">
                      <span className="home-task-date">{moment(task.work_date).format('DD/MM/YYYY')}</span>
                      <Tag color={getTaskStatusColor(task.status)}>{task.status}</Tag>
                    </div>
                  }
                />
              </List.Item>
            )}
            locale={{ emptyText: "No upcoming tasks" }}
          />
        </Card>

        <Card title="Resources" className="resources-card">
          <div className="resources-list">
            {resources.map((resource, index) => (
              <a key={index} href={resource.url} className="resource-item" target="_blank" rel="noopener noreferrer">
                <Text className="resource-title">{resource.title}</Text>
                <span className="resource-icon">{getResourceIcon(resource.icon)}</span>
              </a>
            ))}
          </div>
        </Card>

        <Card title="Team Map" className="map-card">
          <div className="globe-container" ref={globeEl}></div>
          <Button 
            onClick={toggleRotation} 
            className="rotation-toggle-button"
            icon={isRotating ? <FaPause /> : <FaPlay />}
            aria-label={isRotating ? "Pause Rotation" : "Resume Rotation"}
          />
        </Card>

        <div className="users-birthdays-container">
          <Card title="Online Users" className="online-users-card">
            <div className="online-users-list">
              {onlineUsers.map(user => (
                <Tooltip 
                  key={user.id} 
                  title={getUserTooltipContent(user)}
                  placement="left"
                >
                  <Avatar 
                    className={`online-user-avatar ${user.status === 'inactive' ? 'inactive-avatar' : ''}`}
                    style={{ backgroundColor: userColors[user.id] || '#f56a00' }}
                  >
                    {getInitials(user.name, user.surname)}
                  </Avatar>
                </Tooltip>
              ))}
            </div>
          </Card>

          <Card title="Upcoming Birthdays" className="birthdays-card">
            <List
              dataSource={getUpcomingBirthdays()}
              renderItem={renderBirthdayItem}
              locale={{ emptyText: "No upcoming birthdays" }}
            />
          </Card>
        </div>
      </div>

      <div className="updates-container">
        <Card 
          title={`Team ${teamBriefingType === 'morning' ? "Morning Briefing" : "Afternoon Update"}`}
          className="update-card"
        >
          <div className="update-content">
            {teamBriefing ? (
              <div dangerouslySetInnerHTML={{ __html: formatBriefingContent(teamBriefing) }} />
            ) : (
              <Paragraph>No team briefing has been generated for this {teamBriefingType}.</Paragraph>
            )}
          </div>
        </Card>

        <Card 
          title={`Your Personal ${userBriefingType === 'morning' ? "Morning Briefing" : "Afternoon Update"}`}
          className="update-card"
        >
          <div className="update-content">
            {userBriefing ? (
              <div dangerouslySetInnerHTML={{ __html: formatBriefingContent(userBriefing) }} />
            ) : (
              <Paragraph>No personal briefing has been generated for this {userBriefingType}.</Paragraph>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}

const getTaskStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case 'not started':
      return 'default';
    case 'in progress':
      return 'processing';
    case 'completed':
      return 'success';
    default:
      return 'default';
  }
};

export default Home;