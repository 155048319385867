import React, { useState } from 'react';
import { Input, Button, Avatar } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import './TaskViewUpdates.css';

const TaskViewUpdates = () => {
  const [newUpdate, setNewUpdate] = useState('');

  // Add this dummy data for updates/notes
  const dummyUpdates = [
    {
      id: 1,
      author: 'Morten Krog Katic',
      date: '13 Aug',
      content: 'Vi skal have lavet et eller andet hvortil vi kan uploade en excel/CSV fil, som kan spytte et overblik over leverandører ud.\n\nEksempel:\nI e-conomic kan jeg lave et udtræk fra bankkontoen, over betalinger der er lavet på en kundes konto. Se vedhæftede eksempel.\n\nOutcome skal være et overblik over kundens leverandører, som vi kan bruge til udsendelse af emails til leverandørerne.',
      seen: true,
    },
    // Add more dummy updates as needed
  ];

  const handleNewUpdate = () => {
    // In a real application, you would send this to the server
    console.log('New update:', newUpdate);
    setNewUpdate('');
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  return (
    <div className="updates-notes">
      <div className="new-update">
        <Input.TextArea
          placeholder="Write an update..."
          value={newUpdate}
          onChange={(e) => setNewUpdate(e.target.value)}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
        <Button
          icon={<SendOutlined />}
          onClick={handleNewUpdate}
          type="primary"
        >
          Post
        </Button>
      </div>
      {dummyUpdates.map(update => (
        <div key={update.id} className="update-item">
          <div className="update-header">
            <Avatar className="avatar">{getInitials(update.author)}</Avatar>
            <div className="update-info">
              <div className="author-name">{update.author}</div>
              <div className="update-date">{update.date}</div>
            </div>
          </div>
          <div className="update-content">{update.content}</div>
          <div className="update-footer">
            <Button>Reply</Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TaskViewUpdates;