import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import axiosPublic from '../api/axiosPublic';
import logo from '../assets/logos/logo.png';
import './Login.css';

function Login({ setUser }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    try {
      const response = await axiosPublic.post('/auth/login', { email, password });
      
      if (response.data.access_token) {
        const { access_token, refresh_token, user } = response.data;
        
        // Ensure we're getting the avatar color from the correct place
        const userWithAvatarColor = {
          ...user,
          avatar_color: user.settings?.avatar_color || '#f56a00'
        };

        localStorage.setItem('token', access_token);
        localStorage.setItem('refreshToken', refresh_token);
        localStorage.setItem('user', JSON.stringify(userWithAvatarColor));
        setUser(userWithAvatarColor);

        // Log the user data for debugging
        console.log('User data stored in localStorage:', userWithAvatarColor);

        if (!user.is_registered) {
          navigate('/complete-registration');
        } else {
          navigate('/main-menu');
        }
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(error.response?.data?.error || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <img src={logo} alt="Tuesday Logo" className="login-logo" />
      <h1>Digi-Tal ERP</h1>
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Sign in to your account</h2>
        {error && <p className="error-message">{error}</p>}
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="login-button" disabled={loading}>
          {loading ? <Spin /> : 'Sign in'}
        </button>
      </form>
    </div>
  );
}

export default Login;