export const dealDataConfig = [
    {
      key: 'data.value',
      label: 'Value',
      type: 'number',
      editable: true,
    },
    {
      key: 'main_mailbox',
      label: 'Bilags Mail',
      type: 'text',
      editable: false,
    },
    {
      key: 'corpay_mailbox',
      label: 'Corpay Mail',
      type: 'text',
      editable: false,
    },
    {
      key: 'data.aktiv_kunde',
      label: 'Aktiv kunde',
      type: 'boolean',
      editable: true,
    },
    {
      key: 'data.momsfrister',
      label: 'Momsfrister',
      type: 'dropdown',
      editable: true,
      options: [
        { value: 'Kvartalsvis', label: 'Kvartalsvis', color: '#1890ff' },
        { value: 'Månedlig', label: 'Månedlig', color: '#52c41a' },
        { value: 'Halvårlig', label: 'Halvårlig', color: '#faad14' },
      ],
    },
    {
      key: 'data.lønfrekvens',
      label: 'Lønfrekvens',
      type: 'dropdown',
      editable: true,
      options: [
        { value: 'Ingen løn', label: 'Ingen løn', color: '#d9d9d9' },
        { value: 'Månedsløn', label: 'Månedlig', color: '#1890ff' },
        { value: 'Kvartalsvis', label: 'Kvartalsvis', color: '#52c41a' },
      ],
    },
    {
      key: 'data.lønansvarlig',
      label: 'Lønansvarlig',
      type: 'text',
      editable: true,
    },
    {
      key: 'data.andre_softwares',
      label: 'Andre softwares',
      type: 'text',
      editable: true,
    },
    {
      key: 'data.onboarding_dato',
      label: 'Onboarding dato',
      type: 'date',
      editable: true,
    },
    {
      key: 'data.virksomhedsform',
      label: 'Virksomhedsform',
      type: 'dropdown',
      editable: true,
      options: [
        { value: 'Enkeltmandsvirksomhed', label: 'Enkeltmandsvirksomhed', color: '#1890ff' },
        { value: 'ApS', label: 'ApS', color: '#52c41a' },
        { value: 'A/S', label: 'A/S', color: '#faad14' },
        { value: 'IVS', label: 'IVS', color: '#f5222d' },
      ],
    },
    {
      key: 'data.regnskabsprogram',
      label: 'Regnskabsprogram',
      type: 'dropdown',
      editable: true,
      options: [
        { value: 'Dinero', label: 'Dinero', color: '#1890ff' },
        { value: 'Economic', label: 'Economic', color: '#52c41a' },
        { value: 'Billy', label: 'Billy', color: '#faad14' },
      ],
    },
    {
      key: 'data.hvidvask_compliant',
      label: 'Hvidvask compliant',
      type: 'boolean',
      editable: true,
    },
    {
      key: 'data.sidste_fakturadato',
      label: 'Sidste fakturadato',
      type: 'date',
      editable: true,
    },
    {
      key: 'data.ansvarlig_bogholder',
      label: 'Ansvarlig bogholder',
      type: 'dropdown',
      editable: true,
      options: [
        { value: 'Dennis Suksuwat', label: 'Dennis Suksuwat', color: '#1890ff' },
        { value: 'Mads Brock-Madsen', label: 'Mads Brock-Madsen', color: '#52c41a' },
      ],
    },
    {
      key: 'data.bogføringsfrekvens',
      label: 'Bogføringsfrekvens',
      type: 'dropdown',
      editable: true,
      options: [
        { value: 'Månedlig', label: 'Månedlig', color: '#1890ff' },
        { value: 'Kvartalsvis', label: 'Kvartalsvis', color: '#52c41a' },
        { value: 'Halvårlig', label: 'Halvårlig', color: '#faad14' },
      ],
    },
    {
      key: 'data.seneste_momsopgørelse',
      label: 'Seneste momsopgørelse',
      type: 'text',
      editable: true,
    },
    {
      key: 'data.ansvarlig_lønbogholder',
      label: 'Ansvarlig lønbogholder',
      type: 'text',
      editable: true,
    },
    {
      key: 'data.ansvarlig_revisor_for_årsregnskab',
      label: 'Ansvarlig revisor for årsregnskab',
      type: 'dropdown',
      editable: true,
      options: [
        { value: 'Mads Brock-Madsen', label: 'Mads Brock-Madsen', color: '#1890ff' },
        { value: 'Dennis Suksuwat', label: 'Dennis Suksuwat', color: '#52c41a' },
      ],
    },
    {
      key: 'data.hvem_er_ansvarlig_for_årsregnskab',
      label: 'Hvem er ansvarlig for årsregnskab',
      type: 'dropdown',
      editable: true,
      options: [
        { value: 'Digi-Tal', label: 'Digi-Tal', color: '#1890ff' },
        { value: 'Kunde', label: 'Kunde', color: '#52c41a' },
        { value: 'Ekstern revisor', label: 'Ekstern revisor', color: '#faad14' },
      ],
    },
    {
      key: 'data.hvornår_slutter_næste_regnskabsår',
      label: 'Hvornår slutter næste regnskabsår',
      type: 'date',
      editable: true,
    },
    {
      key: 'data.antal_medarbejdere_til_lønhandtering',
      label: 'Antal medarbejdere til lønhåndtering',
      type: 'number',
      editable: true,
    },
    {
      key: 'data.kan_bankkonto_integreres_i_bogføringssystem',
      label: 'Kan bankkonto integreres i bogføringssystem',
      type: 'boolean',
      editable: true,
    },
    {
      key: 'description',
      label: 'Description',
      type: 'text',
      editable: false,
    },
  ];