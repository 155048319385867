import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoNotificationsOutline, IoPersonAddOutline } from 'react-icons/io5';
import { BsSun, BsMoon, BsGrid } from 'react-icons/bs';
import { Dropdown, Modal, Table, Form, Input, Avatar, DatePicker, Select, Button, message, Tooltip } from 'antd';
import { FaUser, FaUsers, FaCog, FaSignOutAlt } from 'react-icons/fa';
import UserManagementModal from '../modals/UserManagement';
import UserSettings from '../modals/UserSettings';
import ActivityScreen from '../modals/ActivityScreen';
import axios from '../api/axiosConfig';
//import axiosNoAuth from 'axios'; // Not sure if I want to use axios without auth for logout
import './Header.css';
import dayjs from 'dayjs';
import { Layout } from 'antd';
import logoLight from '../assets/logos/tuesday_x500_b.png';
import logoDark from '../assets/logos/tuesday_x500_w.png';

const { Option } = Select;
const { Header: AntHeader } = Layout;

function Header({ theme, toggleTheme }) {
  const navigate = useNavigate();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('user')) || {});
  const userMenuRef = useRef(null);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [isActivityModalVisible, setIsActivityModalVisible] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [countries, setCountries] = useState([]);
  const [form] = Form.useForm();
  const [allUsers, setAllUsers] = useState([]);

  const getInitials = (name, surname) => {
    if (!name && !surname) return '?';
    return `${name ? name[0] : ''}${surname ? surname[0] : ''}`.toUpperCase();
  };

  useEffect(() => {
    fetchCurrentUser();
    fetchAllUsers();
    fetchCountries();
  }, []);

  const fetchCurrentUser = async () => {
    try {
      const response = await axios.get('/user/current');
      const updatedUser = {
        ...response.data,
        avatar_color: response.data.settings?.avatar_color || '#f56a00',
      };
      setCurrentUser(updatedUser);
      localStorage.setItem('user', JSON.stringify(updatedUser));
    } catch (error) {
      console.error('Error fetching current user:', error);
    }
  };

  const fetchAllUsers = async () => {
    try {
      const response = await axios.get('/user');
      setAllUsers(response.data.filter(user => user.id !== currentUser?.id));
      setOnlineUsers(response.data.filter(user => 
        user.id !== currentUser?.id && (user.status === 'online' || user.status === 'inactive')
      ));
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleLogout = async () => {
    try {
      // Use the default axios instance for logout
      await axios.post(`${process.env.REACT_APP_API_URL || 'http://localhost:5000/api'}/auth/logout`, {}, {
        withCredentials: true
      });
      
      // Clear local storage
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      
      // Navigate to login page
      navigate('/login');
      
      message.success('Logged out successfully');
    } catch (error) {
      console.error('Logout failed:', error);
      message.error('Logout failed. Please try again.');
      
      // Even if the server request fails, we should still clear local storage and redirect
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      navigate('/login');
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/user');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchProfile = async () => {
    try {
      const response = await axios.get('/user/profile');
      setProfileData(response.data);
      form.setFieldsValue({
        ...response.data,
        birthday: response.data.birthday ? dayjs(response.data.birthday) : null,
        city: response.data.location_info?.city,
        country: response.data.location_info?.country,
      });
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get('https://restcountries.com/v3.1/all');
      const sortedCountries = response.data
        .map(country => country.name.common)
        .sort((a, b) => a.localeCompare(b));
      setCountries(sortedCountries);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const handleProfileUpdate = async (values) => {
    try {
      const updatedData = {
        ...values,
        birthday: values.birthday ? values.birthday.format('YYYY-MM-DD') : null,
        location_info: {
          city: values.city,
          country: values.country,
        },
      };
      await axios.put('/user/profile', updatedData);
      message.success('Profile updated successfully');
      setShowProfileModal(false);
      fetchProfile(); // Refresh the profile data
    } catch (error) {
      console.error('Error updating profile:', error);
      message.error('Failed to update profile');
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setShowUserMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userMenuRef]);

  const handleMenuClick = ({ key }) => {
    switch (key) {
      case '1':
        setShowProfileModal(true);
        fetchProfile();
        break;
      case '2':
        setShowTeamModal(true);
        fetchUsers();
        break;
      case '3':
        setShowSettingsModal(true);
        console.log('Settings modal opened');
        break;
      case '4':
        handleLogout();
        break;
      default:
        break;
    }
  };

  const menuItems = [
    {
      key: '1',
      icon: <FaUser />,
      label: 'My Profile',
    },
    {
      key: '2',
      icon: <FaUsers />,
      label: 'Teams',
    },
    {
      key: '3',
      icon: <FaCog />,
      label: 'Settings',
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      icon: <FaSignOutAlt />,
      label: 'Logout',
    },
  ];

  const [showUserManagementModal, setShowUserManagementModal] = useState(false);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
    },
  ];

  const renderOnlineAvatars = () => {
    const maxVisibleAvatars = 5;
    const avatarsToShow = onlineUsers.slice(0, maxVisibleAvatars);
    const remainingCount = onlineUsers.length - maxVisibleAvatars;

    return (
      <div className="online-avatars" onClick={() => setIsActivityModalVisible(true)}>
        {avatarsToShow.map((user, index) => (
          <Tooltip key={user.id} title={`${user.name} ${user.surname} (${user.status})`}>
            <Avatar
              className={`stacked-avatar ${user.status === 'inactive' ? 'inactive-avatar' : ''}`}
              style={{
                backgroundColor: user.settings?.avatar_color || '#f56a00',
                zIndex: avatarsToShow.length - index,
              }}
            >
              {getInitials(user.name, user.surname)}
            </Avatar>
          </Tooltip>
        ))}
        {remainingCount > 0 && (
          <Avatar className="stacked-avatar remaining-count" style={{ zIndex: 0 }}>
            +{remainingCount}
          </Avatar>
        )}
      </div>
    );
  };

  const logo = theme === 'dark' ? logoDark : logoLight;

  return (
    <AntHeader className="site-layout-background header">
      <div className="header-left">
        <Button
          type="text"
          icon={<BsGrid />}
          onClick={() => navigate('/main-menu')}
          className="main-menu-button"
        />
        <img src={logo} alt="Logo" className="header-logo" />
        <button className="header-icon-button" onClick={toggleTheme}>
          {theme === 'light' ? <BsMoon /> : <BsSun />}
        </button>
      </div>
      <div className="header-right">
        <div className="header-actions">
          {renderOnlineAvatars()}
          <button className="header-icon-button">
            <IoNotificationsOutline />
          </button>
          <button className="header-icon-button" onClick={() => setShowUserManagementModal(true)}>
            <IoPersonAddOutline />
          </button>
          <Dropdown
            menu={{ items: menuItems, onClick: handleMenuClick }}
            trigger={['click']}
            placement="bottomRight"
          >
            <Avatar
              className="user-avatar"
              style={{ backgroundColor: currentUser.avatar_color || '#f56a00', cursor: 'pointer' }}
            >
              {getInitials(currentUser.name, currentUser.surname)}
            </Avatar>
          </Dropdown>
        </div>
      </div>
      <UserManagementModal
        open={showUserManagementModal}
        onClose={() => setShowUserManagementModal(false)}
      />
      <UserSettings
        visible={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
      />
      <Modal
        title="Team Members"
        open={showTeamModal}
        onCancel={() => setShowTeamModal(false)}
        footer={null}
      >
        <Table 
          dataSource={users} 
          columns={columns} 
          rowKey={(record) => record.id}
        />
      </Modal>
      <Modal
        title="My Profile"
        open={showProfileModal}
        onCancel={() => setShowProfileModal(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleProfileUpdate} layout="vertical">
          <Form.Item name="name" label="Name">
            <Input />
          </Form.Item>
          <Form.Item name="surname" label="Surname">
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email">
            <Input readOnly />
          </Form.Item>
          <Form.Item name="permissions" label="Permissions/Role">
            <Input readOnly />
          </Form.Item>
          <Form.Item name="birthday" label="Birthday">
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item name="city" label="City">
            <Input />
          </Form.Item>
          <Form.Item name="country" label="Country">
            <Select
              showSearch
              placeholder="Select your country"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countries.map(country => (
                <Option key={country} value={country}>{country}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Profile
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <ActivityScreen
        visible={isActivityModalVisible}
        onClose={() => setIsActivityModalVisible(false)}
        users={allUsers}
        currentUser={currentUser}
      />
    </AntHeader>
  );
}

export default Header;