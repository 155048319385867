import React, { useState } from 'react';
import { Tabs, Form, Input, DatePicker, InputNumber, Table, Button, message } from 'antd';
import { CalendarOutlined, TeamOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import './AdminTasks.css';

const { RangePicker } = DatePicker;

function AdminTasks() {
  const [form] = Form.useForm();
  const [workCapacity, setWorkCapacity] = useState(8);
  const [teamCapacity, setTeamCapacity] = useState([]);

  const onFinish = (values) => {
    console.log('Received values:', values);
    message.success('Task configuration saved (dummy action)');
  };

  const TaskDateConfig = () => (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item
        name="onboardingTasksRange"
        label="Onboarding Tasks Timeline"
        rules={[{ required: true, message: 'Please select a date range' }]}
      >
        <RangePicker />
      </Form.Item>
      <Form.Item
        name="recurringTasksRange"
        label="Recurring Tasks Timeline"
        rules={[{ required: true, message: 'Please select a date range' }]}
      >
        <RangePicker />
      </Form.Item>
      <Form.Item
        name="dueDateOffset"
        label="Due Date Offset (days)"
        rules={[{ required: true, message: 'Please enter the due date offset' }]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save Configuration
        </Button>
      </Form.Item>
    </Form>
  );

  const WorkCapacity = () => {
    const columns = [
      { title: 'Team Member', dataIndex: 'name', key: 'name' },
      ...Array.from({ length: 7 }, (_, i) => ({
        title: dayjs().day(i).format('ddd'),
        dataIndex: `day${i}`,
        key: `day${i}`,
        render: (text, record) => (
          <InputNumber
            min={0}
            max={24}
            defaultValue={i === 0 || i === 6 ? 0 : workCapacity}
            onChange={(value) => handleCapacityChange(record.key, `day${i}`, value)}
          />
        ),
      })),
    ];

    const data = [
      { key: 1, name: 'John Doe' },
      { key: 2, name: 'Jane Smith' },
      { key: 3, name: 'Bob Johnson' },
    ];

    const handleCapacityChange = (key, day, value) => {
      const updatedCapacity = [...teamCapacity];
      const memberIndex = updatedCapacity.findIndex((member) => member.key === key);
      if (memberIndex === -1) {
        updatedCapacity.push({ key, [day]: value });
      } else {
        updatedCapacity[memberIndex] = { ...updatedCapacity[memberIndex], [day]: value };
      }
      setTeamCapacity(updatedCapacity);
    };

    return (
      <>
        <Form layout="inline" style={{ marginBottom: 16 }}>
          <Form.Item label="Default Work Capacity (hours)">
            <InputNumber
              min={0}
              max={24}
              value={workCapacity}
              onChange={(value) => setWorkCapacity(value)}
            />
          </Form.Item>
        </Form>
        <Table columns={columns} dataSource={data} pagination={false} />
      </>
    );
  };

  const items = [
    {
      key: 'taskDateConfig',
      label: (
        <span>
          <CalendarOutlined />
          Task Date Config
        </span>
      ),
      children: <TaskDateConfig />,
    },
    {
      key: 'workCapacity',
      label: (
        <span>
          <TeamOutlined />
          Work Capacity
        </span>
      ),
      children: <WorkCapacity />,
    },
  ];

  return (
    <div className="admin-tasks">
      <h2>Tasks and Workload Configuration</h2>
      <Tabs tabPosition="left" items={items} />
    </div>
  );
}

export default AdminTasks;