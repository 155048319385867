// frontend/src/modals/SendEmailModal.js

import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form, Input, Button, message, Divider, Tag } from 'antd';
import { SendOutlined, PlusOutlined } from '@ant-design/icons';
import axios from '../api/axiosConfig';
import './SendEmailModal.css';

const { TextArea } = Input;

const SendEmailModal = ({ visible, onClose, contact, dealId, replyToEmail }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [ccRecipients, setCcRecipients] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const textAreaRef = useRef(null);
  const [replyType, setReplyType] = useState('reply'); // 'reply' or 'replyAll'

  // Get the current user from localStorage
  const currentUser = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setCcRecipients([]);
      setInputVisible(false);
      setInputValue('');
      
      // Safely reset textarea height
      if (textAreaRef.current && textAreaRef.current.resizableTextArea) {
        textAreaRef.current.resizableTextArea.textArea.style.height = 'auto';
      }
      
      if (replyToEmail) {
        form.setFieldsValue({
          subject: `Re: ${replyToEmail.subject}`,
          body: ''
        });
        
        // Set initial CC recipients for Reply All
        if (replyType === 'replyAll') {
          setCcRecipients(replyToEmail.cc || []);
        }
      }
    }
  }, [visible, form, replyToEmail, replyType]);

  const handleSend = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      
      if (replyToEmail) {
        // Sending a reply
        const replyData = {
          body: values.body,
          reply_to_email: replyToEmail.message_id, // Use Graph message_id
          user_id: currentUser.id,
          reply_type: replyType,
          deal_id: dealId
        };
        console.log('Sending reply data:', replyData);
        await axios.post('/contactmail/send-reply', replyData);
        message.success('Reply sent successfully!');
      } else {
        // Sending a new email
        const emailData = {
          to: contact?.email,
          cc: ccRecipients,
          subject: values.subject,
          body: values.body,
          deal_id: dealId,
          user_id: currentUser.id,
          // No reply_to_email or thread_id
        };
        console.log('Sending email data:', emailData);
        await axios.post('/contactmail/send-email', emailData);
        message.success('Email sent successfully!');
      }
      
      form.resetFields();
      setCcRecipients([]);
      onClose();
    } catch (error) {
      console.error('Error sending email:', error);
      message.error('Failed to send email.');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (removedTag) => {
    const newTags = ccRecipients.filter(tag => tag !== removedTag);
    setCcRecipients(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputConfirm = () => {
    if (inputValue && isValidEmail(inputValue) && ccRecipients.indexOf(inputValue) === -1) {
      setCcRecipients([...ccRecipients, inputValue]);
      setInputValue('');
    } else if (inputValue && !isValidEmail(inputValue)) {
      message.error('Please enter a valid email address');
    }
    setInputVisible(false);
  };

  return (
    <Modal
      open={visible}
      title={replyToEmail ? "Reply to Email" : "Compose Email"}
      onCancel={onClose}
      footer={null}
      className="send-email-modal"
    >
      <Form form={form} layout="vertical" className="send-email-form">
        {replyToEmail && (
          <div className="reply-preview">
            <h4>Replying to:</h4>
            <p><strong>From:</strong> {replyToEmail.sender_name} ({replyToEmail.sender_email})</p>
            <p><strong>Subject:</strong> {replyToEmail.subject}</p>
            <p><strong>Sent:</strong> {new Date(replyToEmail.sent_at).toLocaleString()}</p>
          </div>
        )}
        {!replyToEmail && (
          <Form.Item
            name="to"
            label="To"
            initialValue={contact?.email}
            rules={[{ required: true, message: 'Please input the recipient email!' }]}
          >
            <Input className="recipient-input" />
          </Form.Item>
        )}
        {!replyToEmail && (
          <Form.Item label="CC" className="cc-container">
            {ccRecipients.map((tag) => (
              <Tag
                className="cc-tag"
                key={tag}
                closable
                onClose={() => handleClose(tag)}
              >
                {tag}
              </Tag>
            ))}
            {inputVisible && (
              <Input
                ref={inputRef}
                type="text"
                size="small"
                className="cc-input"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
              />
            )}
            {!inputVisible && (
              <Tag className="cc-add-tag" onClick={showInput}>
                <PlusOutlined /> Add CC
              </Tag>
            )}
          </Form.Item>
        )}
        <Form.Item
          name="subject"
          label="Subject"
          rules={[{ required: !replyToEmail, message: 'Please input the subject!' }]}
        >
          <Input 
            className="subject-input" 
            disabled={replyToEmail} // Disable subject field when replying
          />
        </Form.Item>
        <Form.Item
          name="body"
          label="Message"
          rules={[{ required: true, message: 'Please input the email body!' }]}
        >
          <TextArea 
            rows={8} 
            className="message-input" 
            ref={textAreaRef}
            autoSize={{ minRows: 8, maxRows: 20 }}
          />
        </Form.Item>
        <Divider />
        <div className="modal-footer">
          <Button onClick={onClose}>Cancel</Button>
          <Button 
            type="primary" 
            onClick={handleSend} 
            loading={loading} 
            icon={<SendOutlined />}
          >
            {replyToEmail ? 'Send Reply' : 'Send'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SendEmailModal;