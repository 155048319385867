import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BsSun, BsMoon, BsGrid } from 'react-icons/bs';
import { Dropdown, Avatar, Button } from 'antd';
import { FaSignOutAlt } from 'react-icons/fa';
import axios from '../api/axiosConfig';
import './ErpHeader.css';
import { Layout } from 'antd';

const { Header: AntHeader } = Layout;

function ErpHeader({ theme, toggleTheme, logo, icon: Icon, title }) {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user')) || {};

  const getInitials = (name, surname) => {
    if (!name && !surname) return '?';
    return `${name ? name[0] : ''}${surname ? surname[0] : ''}`.toUpperCase();
  };

  const handleLogout = async () => {
    try {
      await axios.post('/auth/logout');
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      navigate('/login');
    }
  };

  const menuItems = [
    {
      key: '1',
      icon: <FaSignOutAlt />,
      label: 'Logout',
      onClick: handleLogout,
    },
  ];

  return (
    <AntHeader className="erp-header">
      <div className="erp-header-left">
        <Button
          type="text"
          icon={<BsGrid />}
          onClick={() => navigate('/main-menu')}
          className="erp-main-menu-button"
        />
        <button className="erp-header-icon-button" onClick={toggleTheme}>
          {theme === 'light' ? <BsMoon /> : <BsSun />}
        </button>
      </div>
      <div className="erp-header-center">
        {logo ? (
          <img src={logo} alt="App Logo" className="erp-header-logo" />
        ) : (
          <div className="erp-header-title">
            {Icon && <Icon className="erp-header-icon" />}
            <span>{title}</span>
          </div>
        )}
      </div>
      <div className="erp-header-right">
        <Dropdown menu={{ items: menuItems }} trigger={['click']} placement="bottomRight">
          <Avatar
            className="erp-user-avatar"
            style={{ 
              backgroundColor: user.avatar_color || '#f56a00',
              cursor: 'pointer' 
            }}
          >
            {getInitials(user.name, user.surname)}
          </Avatar>
        </Dropdown>
      </div>
    </AntHeader>
  );
}

export default ErpHeader;