import React, { useState, useEffect } from 'react';
import { Form, TimePicker, Button, message, Tabs, Checkbox, Space, Row, Col, Table } from 'antd';
import { DashboardOutlined, ReconciliationOutlined, FileTextOutlined, PlusOutlined, TeamOutlined, ScheduleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import axios from '../../api/axiosConfig';
import './AdminSchedule.css';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const { TabPane } = Tabs;

function AdminSchedule() {
  const [form] = Form.useForm();
  const [scheduleConfigs, setScheduleConfigs] = useState({});
  const [activeTab, setActiveTab] = useState('fetch_dashboard_data');

  useEffect(() => {
    fetchScheduleConfigs();
  }, []);

  const fetchScheduleConfigs = async () => {
    try {
      const response = await axios.get('/admin/schedule-config');
      const configs = response.data.reduce((acc, config) => {
        acc[config.job_name] = config;
        return acc;
      }, {});
      setScheduleConfigs(configs);
      form.setFieldsValue(formatConfigsForForm(configs));
    } catch (error) {
      console.error('Error fetching schedule configs:', error);
      message.error('Failed to fetch schedule configurations');
    }
  };

  const formatConfigsForForm = (configs) => {
    const formattedConfigs = {};
    Object.entries(configs).forEach(([jobName, config]) => {
      if (jobName === 'team_briefings') {
        formattedConfigs[jobName] = {
          days_of_week: config.days_of_week,
          morning_time: dayjs(config.times_of_day[0], 'HH:mm'),
          afternoon_time: dayjs(config.times_of_day[1], 'HH:mm'),
        };
      } else {
        formattedConfigs[jobName] = {
          days_of_week: config.days_of_week,
          times_of_day: config.times_of_day.map(time => dayjs(time, 'HH:mm')),
        };
      }
    });
    return formattedConfigs;
  };

  const onFinish = async (jobName) => {
    try {
      const values = form.getFieldsValue()[jobName];
      const days_of_week = values?.days_of_week || [];
      let times_of_day = values?.times_of_day || [];

      // Special handling for team briefings
      if (jobName === 'team_briefings') {
        const morning_time = values?.morning_time?.format('HH:mm');
        const afternoon_time = values?.afternoon_time?.format('HH:mm');
        
        if (!morning_time || !afternoon_time) {
          message.error('Please specify both morning and afternoon briefing times.');
          return;
        }

        times_of_day = [morning_time, afternoon_time];
      } else {
        if (days_of_week.length > 0 && times_of_day.length === 0) {
          message.error('Please specify at least one time of day when days are selected.');
          return;
        }

        if (times_of_day.some(time => !time)) {
          message.error('Please fill in all time inputs or remove empty ones.');
          return;
        }

        times_of_day = times_of_day.map(time => time.format('HH:mm'));
      }

      const formattedValues = {
        job_name: jobName,
        days_of_week: days_of_week,
        times_of_day: times_of_day,
      };

      // For team briefings, add morning_time and afternoon_time separately
      if (jobName === 'team_briefings') {
        formattedValues.morning_time = times_of_day[0];
        formattedValues.afternoon_time = times_of_day[1];
      }

      await axios.post('/admin/schedule-config', formattedValues);
      message.success('Schedule configuration saved');
      fetchScheduleConfigs();
    } catch (error) {
      console.error('Error saving schedule config:', error);
      message.error('Failed to save schedule configuration');
    }
  };

  const formatOverviewData = (configs) => {
    return Object.entries(configs).map(([jobName, config]) => ({
      key: jobName,
      job: jobName.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
      days: config.days_of_week.map(day => ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][day]).join(', '),
      times: config.times_of_day.join(', ')
    }));
  };

  const OverviewContent = () => (
    <>
      <h3>Schedule Overview</h3>
      <Table
        dataSource={formatOverviewData(scheduleConfigs)}
        columns={[
          { title: 'Job', dataIndex: 'job', key: 'job' },
          { title: 'Days', dataIndex: 'days', key: 'days' },
          { title: 'Times (UTC)', dataIndex: 'times', key: 'times' },
        ]}
        pagination={false}
      />
    </>
  );

  const TeamBriefingsContent = () => (
    <>
      <h3>Team Briefings Schedule</h3>
      <Form.Item
        name={["team_briefings", "days_of_week"]}
        label="Days of the Week"
      >
        <Checkbox.Group className="days-of-week-grid">
          <Row gutter={[16, 16]}>
            {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day, index) => (
              <Col xs={24} sm={16} md={12} lg={8} key={index}>
                <Checkbox value={index} className="day-checkbox">
                  {day}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item required>
        <Space align="center" className="time-input-group">
          <Form.Item
            name={["team_briefings", "morning_time"]}
            label="Morning Briefing Time"
            rules={[{ required: true, message: 'Please input the morning briefing time.' }]}
          >
            <TimePicker 
              format="HH:mm" 
              use12Hours={false} 
              allowClear={false}
              inputReadOnly={true}
            />
          </Form.Item>
          <span>UTC</span>
        </Space>
        <Space align="center" className="time-input-group">
          <Form.Item
            name={["team_briefings", "afternoon_time"]}
            label="Afternoon Briefing Time"
            rules={[{ required: true, message: 'Please input the afternoon briefing time.' }]}
          >
            <TimePicker 
              format="HH:mm" 
              use12Hours={false} 
              allowClear={false}
              inputReadOnly={true}
            />
          </Form.Item>
          <span>UTC</span>
        </Space>
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={() => onFinish("team_briefings")}>
          Save Configuration
        </Button>
      </Form.Item>
    </>
  );

  const ScheduleContent = ({ jobName, title }) => (
    <>
      <h3>{title}</h3>
      <Form.Item
        name={[jobName, "days_of_week"]}
        label="Days of the Week"
      >
        <Checkbox.Group className="days-of-week-grid">
          <Row gutter={[16, 16]}>
            {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day, index) => (
              <Col xs={24} sm={16} md={12} lg={8} key={index}>
                <Checkbox value={index} className="day-checkbox">
                  {day}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Form.List name={[jobName, "times_of_day"]}>
        {(fields, { add, remove }) => (
          <div className="times-of-day-container">
            {fields.map((field, index) => (
              <Form.Item
                {...field}
                label={index === 0 ? 'Times of Day' : ''}
                required={false}
                key={field.key}
              >
                <Space align="center" className="time-input-group">
                  <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[{ required: true, message: 'Please input the time or delete this field.' }]}
                    noStyle
                  >
                    <TimePicker 
                      format="HH:mm" 
                      use12Hours={false} 
                      allowClear={false}
                      inputReadOnly={true}
                    />
                  </Form.Item>
                  <span>UTC</span>
                  {fields.length > 1 && (
                    <Button 
                      onClick={() => remove(field.name)}
                      className="delete-time-button"
                    >
                      Delete
                    </Button>
                  )}
                </Space>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="default"
                onClick={() => add()}
                icon={<PlusOutlined />}
                className="add-time-button"
              />
            </Form.Item>
          </div>
        )}
      </Form.List>
      <Form.Item>
        <Button type="primary" onClick={() => onFinish(jobName)}>
          Save Configuration
        </Button>
      </Form.Item>
    </>
  );

  return (
    <div className="admin-schedule">
      <h2>Admin Schedule Configuration</h2>
      <Form form={form} layout="vertical">
        <Tabs 
          tabPosition="left"
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
        >
          <TabPane
            tab={<span><ScheduleOutlined />Overview</span>}
            key="overview"
          >
            <OverviewContent />
          </TabPane>
          <TabPane
            tab={<span><DashboardOutlined />Dashboard Data Fetch & Processing</span>}
            key="fetch_dashboard_data"
          >
            <ScheduleContent jobName="fetch_dashboard_data" title="Dashboard Data Fetch & Processing Schedule" />
          </TabPane>
          <TabPane
            tab={<span><ReconciliationOutlined />Recurring Tasks Processing</span>}
            key="recurring_tasks_processing"
          >
            <ScheduleContent jobName="recurring_tasks_processing" title="Recurring Tasks Processing Schedule" />
          </TabPane>
          <TabPane
            tab={<span><FileTextOutlined />Invoice Processing</span>}
            key="invoice_processing"
          >
            <ScheduleContent jobName="invoice_processing" title="Invoice Processing Schedule" />
          </TabPane>
          <TabPane
            tab={<span><TeamOutlined />Team Briefings</span>}
            key="team_briefings"
          >
            <TeamBriefingsContent />
          </TabPane>
        </Tabs>
      </Form>
    </div>
  );
}

export default AdminSchedule;