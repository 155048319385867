import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../api/axiosConfig';
import { Table, Input, Select, Button, Avatar, Tooltip } from 'antd';
import { SearchOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import OnboardingDetails from './OnboardingDetails';
import './OnboardingCases.css';

const { Option } = Select;

const OnboardingCases = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [statusFilter, setStatusFilter] = useState('All');
  const [assigneeFilter, setAssigneeFilter] = useState('');
  const [selectedOnboardingId, setSelectedOnboardingId] = useState(null);
  const [view, setView] = useState('list');
  const [stages, setStages] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchOnboardings();
    fetchStages();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filterParam = params.get('filter');
    if (filterParam) {
      setStatusFilter(parseInt(filterParam, 10));
    }
  }, [location]);

  useEffect(() => {
    applyFilters(statusFilter, assigneeFilter);
  }, [data, statusFilter, assigneeFilter]);

  const fetchOnboardings = async () => {
    try {
      const response = await axios.get('/onboarding/deals');
      const mappedData = response.data.onboardings.map(onboarding => ({
        key: onboarding.id,
        creationDate: new Date(onboarding.created_at).toLocaleDateString(),
        name: onboarding.deal.name,
        assignee: onboarding.assignee ? [onboarding.assignee] : [],
        lastUpdated: new Date(onboarding.updated_at).toLocaleDateString(),
        status: onboarding.stage,
        statusName: onboarding.stage_name || `Unknown (${onboarding.stage})`,
        tasks: onboarding.tasks || [],
      }));
      setData(mappedData);
    } catch (error) {
      console.error('Error fetching onboardings:', error);
    }
  };

  const fetchStages = async () => {
    try {
      const response = await axios.get('/onboarding/stages');
      setStages(response.data);
    } catch (error) {
      console.error('Error fetching stages:', error);
    }
  };

  const handleStatusFilter = (value) => {
    setStatusFilter(value === 'All' ? 'All' : parseInt(value, 10));
    applyFilters(value === 'All' ? 'All' : parseInt(value, 10), assigneeFilter);
  };

  const handleAssigneeFilter = (value) => {
    setAssigneeFilter(value);
    applyFilters(statusFilter, value);
  };

  const applyFilters = (status, assignee) => {
    let filtered = data;
    if (status && status !== 'All') {
      filtered = filtered.filter(item => item.status === status);
    }
    if (assignee) {
      filtered = filtered.filter(item => 
        item.assignee.some(a => 
          `${a.name} ${a.surname}`.toLowerCase().includes(assignee.toLowerCase())
        )
      );
    }
    setFilteredData(filtered);
  };

  const handleRowClick = (record) => {
    navigate(`/onboarding/cases/${record.key}`);
  };

  const handleBackToList = () => {
    setSelectedOnboardingId(null);
    setView('list');
  };

  const getInitials = (name) => {
    if (!name) return '?';
    return name.split(' ').map(n => n[0]).join('').toUpperCase();
  };

  const columns = [
    {
      title: 'Creation Date',
      dataIndex: 'creationDate',
      key: 'creationDate',
      sorter: (a, b) => new Date(a.creationDate) - new Date(b.creationDate),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search name"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: 'Assignee',
      dataIndex: 'assignee',
      key: 'assignee',
      render: assignee => (
        <div className="assignee-column">
          {assignee.map((assignee, index) => (
            <Tooltip key={assignee.id} title={`${assignee.name} ${assignee.surname}`}>
              <Avatar
                className="assignee-avatar"
                style={{ backgroundColor: assignee.avatar_color || '#f56a00' }}
              >
                {getInitials(`${assignee.name} ${assignee.surname}`)}
              </Avatar>
            </Tooltip>
          ))}
        </div>
      ),
    },
    {
      title: 'Last Updated',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      sorter: (a, b) => new Date(a.lastUpdated) - new Date(b.lastUpdated),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => {
        if (!status) {
          console.warn(`Missing status for record:`, record);
          return <span className="status unknown">Unknown</span>;
        }
        return (
          <span className={`status status-${status}`}>
            {record.statusName || `Unknown (${status})`}
          </span>
        );
      },
    },
  ];

  const handleStageChange = (newStage, newStageName) => {
    setData(prevData => prevData.map(item => 
      item.key === selectedOnboardingId ? { ...item, status: newStage, statusName: newStageName } : item
    ));
    setFilteredData(prevData => prevData.map(item => 
      item.key === selectedOnboardingId ? { ...item, status: newStage, statusName: newStageName } : item
    ));
  };

  return (
    <div className="onboarding">
      {view === 'list' && (
        <div className="onboarding-list">
          <div className="list-header">
            <Button icon={<ArrowLeftOutlined />} onClick={() => navigate('/onboarding')}>Back to Dashboard</Button>
            <h1>Cases</h1>
          </div>
          <div className="filters">
            <Select
              value={statusFilter === 'All' ? 'All' : statusFilter.toString()}
              style={{ width: 200, marginRight: 16 }}
              onChange={handleStatusFilter}
            >
              <Option value="All">All Statuses</Option>
              {stages.map(stage => (
                <Option key={stage.id} value={stage.id.toString()}>{stage.name}</Option>
              ))}
            </Select>
            <Input
              placeholder="Search assignee"
              style={{ width: 200 }}
              onChange={e => handleAssigneeFilter(e.target.value)}
            />
          </div>
          <Table 
            columns={columns} 
            dataSource={filteredData} 
            className="onboarding-table"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
          />
        </div>
      )}
      {view === 'details' && (
        <div className="onboarding-details">
          <OnboardingDetails 
            onboardingId={selectedOnboardingId} 
            onBack={handleBackToList} 
            onStageChange={handleStageChange}
          />
        </div>
      )}
    </div>
  );
};

export default OnboardingCases;