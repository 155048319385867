import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Avatar, Tooltip, Tag, Button, Modal, Input, Drawer } from 'antd';
import { EyeOutlined, MessageOutlined, ClockCircleOutlined, CalendarOutlined, SendOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useNavigate } from 'react-router-dom';
import './TaskKanban.css';
import TaskCompleteDrawer from '../drawers/TaskCompleteDrawer';

dayjs.extend(utc);
dayjs.extend(timezone);

const TaskKanban = ({ tasks, onTaskUpdate, boardStatusOptions, cardConfig, dealDataConfig, taskType }) => {
  const [localTasks, setLocalTasks] = useState(tasks);
  const navigate = useNavigate();
  const [hoveredTaskId, setHoveredTaskId] = useState(null);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [newUpdate, setNewUpdate] = useState('');
  const [isCompleteDrawerVisible, setIsCompleteDrawerVisible] = useState(false);
  const [completedTask, setCompletedTask] = useState(null);

  useEffect(() => {
    setLocalTasks(tasks);
  }, [tasks]);

  const getTasksForStatus = (status) => {
    return localTasks.filter(task => task.board_status === status);
  };

  const getColumnColor = (status) => {
    const option = boardStatusOptions.find(o => o.value === status);
    return option ? option.color : 'default';
  };

  const handleOpenTask = (taskId) => {
    navigate(`${taskId}`);
  };

  const getTagColor = (key, value) => {
    const config = dealDataConfig.find(item => item.key === `data.${key}`);
    if (config && config.type === 'dropdown') {
      const option = config.options.find(opt => opt.value === value);
      return option ? option.color : 'default';
    }
    return 'default';
  };

  const mapFrequencyToLabel = (frequency) => {
    if (!frequency || !frequency.freq) return 'N/A';
    const mapping = {
      DAILY: 'Daily',
      WEEKLY: 'Weekly',
      MONTHLY: 'Monthly',
      QUARTERLY: 'Quarterly',
      YEARLY: 'Yearly'
    };
    return mapping[frequency.freq.toUpperCase()] || frequency.freq;
  };

  const handleMessageIconClick = (task, e) => {
    e.stopPropagation();
    setSelectedTask(task);
    setIsUpdateModalVisible(true);
  };

  const handleNewUpdate = () => {
    // In a real application, you would send this to the server
    console.log('New update for task', selectedTask.id, ':', newUpdate);
    setNewUpdate('');
    setIsUpdateModalVisible(false);
  };

  const formatDate = (dateString) => {
    return dayjs(dateString).format('YYYY-MM-DD');
  };

  const renderTaskContent = (task) => {
    // Static dummy data for updates count
    const updatesCount = 3;

    return (
      <>
        <div className="kanban-card-header">
          <div className="kanban-card-deal">
            <Tag color="blue">{task.deal_name}</Tag>
          </div>
        </div>
        <h4 className="kanban-card-title">{task.description}</h4>
        {cardConfig.showFrequency && (
          <div className="kanban-card-frequency">
            <Tag color={cardConfig.showFrequency ? "var(--primary-color)" : "default"}>
              {mapFrequencyToLabel(task.frequency)}
            </Tag>
          </div>
        )}
        <div className="kanban-card-dates">
          <div className="kanban-card-date">
            <ClockCircleOutlined className="kanban-card-icon" />
            <span>{formatDate(task.next_work_date)}</span>
            <span className="kanban-card-date-label">Work Date</span>
          </div>
          <div className="kanban-card-date">
            <CalendarOutlined className="kanban-card-icon" />
            <span>{formatDate(task.next_due_date)}</span>
            <span className="kanban-card-date-label">Due Date</span>
          </div>
        </div>
        <div className="kanban-card-deals">
          {dealDataConfig && dealDataConfig.map(item => {
            const key = item.key.replace('data.', '');
            const value = task.deal_data && task.deal_data[key];
            if (value) {
              return (
                <Tooltip key={item.key} title={item.label}>
                  <div className="kanban-card-deal">
                    <Tag color={getTagColor(key, value)}>
                      {`${value}`}
                    </Tag>
                  </div>
                </Tooltip>
              );
            }
            return null;
          })}
        </div>
        <div className="kanban-card-footer">
          {cardConfig.showAssignee && (
            <div className="kanban-card-assignee">
              <Avatar.Group>
                {task.assignees.map(assignee => (
                  <Tooltip key={assignee.id} title={assignee.full_name}>
                    <Avatar style={{ backgroundColor: assignee.avatar_color }}>
                      {assignee.initials}
                    </Avatar>
                  </Tooltip>
                ))}
              </Avatar.Group>
            </div>
          )}
          <Button
            className="kanban-card-open-button"
            icon={<EyeOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              handleOpenTask(task.id);
            }}
          >
            Open
          </Button>
          <div 
            className="kanban-card-activity"
            onMouseEnter={() => setHoveredTaskId(task.id)}
            onMouseLeave={() => setHoveredTaskId(null)}
            onClick={(e) => handleMessageIconClick(task, e)}
          >
            <MessageOutlined />
            {updatesCount > 0 && <span className="updates-count">{updatesCount}</span>}
          </div>
        </div>
      </>
    );
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination, draggableId } = result;

    if (source.droppableId !== destination.droppableId) {
      const updatedTask = localTasks.find(task => task.id.toString() === draggableId);
      if (updatedTask) {
        const newStatus = destination.droppableId;
        const isLastStatus = boardStatusOptions[boardStatusOptions.length - 1].value === newStatus;
        const isMovingFromLastStatus = boardStatusOptions[boardStatusOptions.length - 1].value === source.droppableId;
        
        let newProgressStatus;
        if (isLastStatus) {
          newProgressStatus = 'Completed';
        } else if (isMovingFromLastStatus) {
          newProgressStatus = 'In progress';
        } else {
          newProgressStatus = updatedTask.progress_status;
        }

        setLocalTasks(prevTasks => 
          prevTasks.map(task => 
            task.id.toString() === draggableId 
              ? { ...task, board_status: newStatus, progress_status: newProgressStatus }
              : task
          )
        );

        const updateData = { 
          board_status: newStatus,
          progress_status: newProgressStatus
        };

        onTaskUpdate(updatedTask.id, updateData);

        if (isLastStatus) {
          setCompletedTask(updatedTask);
          setIsCompleteDrawerVisible(true);
        }
      }
    }
  };

  useEffect(() => {
    if (completedTask) {
      setIsCompleteDrawerVisible(true);
    }
  }, [completedTask]);

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="kanban-board">
          {boardStatusOptions.map((statusOption) => {
            const tasksInStatus = getTasksForStatus(statusOption.value);
            return (
              <div key={statusOption.value} className="kanban-column">
                <h3 className="kanban-column-title" style={{ backgroundColor: getColumnColor(statusOption.value) }}>
                  {statusOption.value} <span className="kanban-column-count">{tasksInStatus.length}</span>
                </h3>
                <Droppable droppableId={statusOption.value} type="TASK">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="kanban-column-content"
                    >
                      {tasksInStatus.map((task, index) => (
                        <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`kanban-card ${snapshot.isDragging ? 'is-dragging' : ''}`}
                            >
                              {renderTaskContent(task)}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            );
          })}
        </div>
      </DragDropContext>
      <Modal
        title={`Updates for ${selectedTask?.description}`}
        open={isUpdateModalVisible}
        onCancel={() => setIsUpdateModalVisible(false)}
        footer={null}
      >
        <div className="updates-list">
          {/* Dummy data for updates */}
          {[1, 2, 3].map((index) => (
            <div key={index} className="update-item">
              <Avatar>U{index}</Avatar>
              <div className="update-content">
                <div className="update-author">User {index}</div>
                <div className="update-text">This is update {index} for the task.</div>
                <div className="update-date">2023-05-{index.toString().padStart(2, '0')}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="new-update">
          <Input.TextArea
            placeholder="Write an update..."
            value={newUpdate}
            onChange={(e) => setNewUpdate(e.target.value)}
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
          <Button
            icon={<SendOutlined />}
            onClick={handleNewUpdate}
            type="primary"
            style={{ marginTop: '8px' }}
          >
            Post
          </Button>
        </div>
      </Modal>
      <Drawer
        title="Task Completed"
        placement="right"
        onClose={() => {
          setIsCompleteDrawerVisible(false);
          setCompletedTask(null);
        }}
        open={isCompleteDrawerVisible}
        width={400}
      >
        {completedTask && <TaskCompleteDrawer task={completedTask} taskType={taskType} />}
      </Drawer>
    </>
  );
};

export default TaskKanban;