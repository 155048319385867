export const permissionLevels = {
    owner: ['owner'],
    admin: ['owner', 'admin'],
    user: ['owner', 'admin', 'user', 'accounting']
  };
  
  export const hasRequiredPermission = (userPermissions, requiredPermission) => {
    console.log('Checking permissions. User permissions:', userPermissions);
    console.log('Required permission:', requiredPermission);

    // Convert userPermissions to an array if it's a string
    const permissionsArray = Array.isArray(userPermissions) ? userPermissions : [userPermissions];

    const result = permissionsArray.some(userPerm => permissionLevels[requiredPermission].includes(userPerm));
    console.log('Permission check result:', result);
    return result;
  };