import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { FaExclamationCircle, FaUserFriends, FaHome } from 'react-icons/fa';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { BsPersonBadge, BsBarChartFill } from 'react-icons/bs';
import { IoMdPeople } from 'react-icons/io';
import { MdOutlineAccountBalance, MdPersonRemove } from 'react-icons/md';
import { GiBookmark } from 'react-icons/gi';
import { AiOutlineSchedule, AiOutlineFund } from 'react-icons/ai';
import { MdPersonAdd, MdWork } from 'react-icons/md';
import axios from '../api/axiosConfig';
import './Sidebar.css';
import io from 'socket.io-client';

const { Sider } = Layout;
const { SubMenu } = Menu;

function Sidebar({ collapsed, toggleCollapsed, theme }) {
  const [pendingDealsCount, setPendingDealsCount] = useState(0);
  const location = useLocation();

  useEffect(() => {
    fetchPendingDealsCount();
    const socket = io(process.env.REACT_APP_API_URL || 'http://localhost:5000');
    socket.on('pending_deal_added', (data) => {
      setPendingDealsCount(data.new_count);
    });
    socket.on('pending_deal_imported', (data) => {
      setPendingDealsCount(data.new_count);
    });
    return () => socket.disconnect();
  }, []);

  const fetchPendingDealsCount = async () => {
    try {
      const response = await axios.get('/account/pending-deals-count');
      setPendingDealsCount(response.data.count);
    } catch (error) {
      console.error('Error fetching pending deals count:', error);
    }
  };

  const menuItems = [
    { key: '/home', icon: <FaHome />, label: 'Home' },
    { key: '/work', icon: <MdWork />, label: 'My Work' },
    { key: '/dashboard', icon: <BsBarChartFill />, label: 'Dashboard' },
    {
      key: 'customers',
      icon: <FaUserFriends />,
      label: 'Customers',
      children: [
        { key: '/customers/deals', label: 'Deals', badge: pendingDealsCount },
        { key: '/customers/accounts', label: 'Accounts' },
        { key: '/customers/contacts', label: 'Contacts' },
        { key: '/customers/products', label: 'Products & Billing' },
      ],
    },
    { key: '/onboarding', icon: <MdPersonAdd />, label: 'Onboarding' },
    { key: '/offboarding', icon: <MdPersonRemove />, label: 'Offboarding' },
  ];

  const taskBoardItems = [
    { key: '/boards/bogforing', icon: <GiBookmark />, label: 'Bogføring' },
    { key: '/boards/lon', icon: <MdOutlineAccountBalance />, label: 'Løn' },
    { key: '/boards/moms', icon: <AiOutlineFund />, label: 'Moms' },
    { key: '/boards/arsafslutning', icon: <IoMdPeople />, label: 'Årsafslutning' },
    { key: '/boards/andre-opgaver', icon: <BsPersonBadge />, label: 'Andre opgaver' },
    { key: '/boards/activities', icon: <AiOutlineSchedule />, label: 'Activities' },
  ];

  return (
    <Sider 
      trigger={null} 
      collapsible 
      collapsed={collapsed} 
      theme={theme} 
      className="sidebar"
    >
      <div className={`sidebar-header ${collapsed ? 'collapsed' : ''}`}>
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={toggleCollapsed}
          className="trigger"
        />
      </div>
      <Menu
        theme={theme}
        mode="inline"
        defaultSelectedKeys={['1']}
        selectedKeys={[location.pathname]}
      >
        {menuItems.map((item) =>
          item.children ? (
            <SubMenu key={item.key} icon={item.icon} title={item.label}>
              {item.children.map((child) => (
                <Menu.Item key={child.key}>
                  <Link to={child.key}>
                    {child.label}
                    {child.badge > 0 && (
                      <span className={`badge ${child.badge === '!' ? 'badge-error' : ''}`}>
                        {child.badge === '!' ? <FaExclamationCircle /> : child.badge}
                      </span>
                    )}
                  </Link>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.key}>{item.label}</Link>
            </Menu.Item>
          )
        )}
        <Menu.Divider />
        <Menu.ItemGroup 
          title={
            <div className="task-boards-title-container">
              <span className={`task-boards-title ${collapsed ? 'collapsed' : ''}`}>
                Task Boards
              </span>
              <span className={`task-boards-title short ${collapsed ? 'collapsed' : ''}`}>
                Boards
              </span>
            </div>
          }
        >
          {taskBoardItems.map((item) => (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.key}>{item.label}</Link>
            </Menu.Item>
          ))}
        </Menu.ItemGroup>
      </Menu>
    </Sider>
  );
}

export default Sidebar;