import React, { useState } from 'react';
import { Form, InputNumber, Button, Checkbox, Alert } from 'antd';
import './TaskCompleteDrawer.css';

const TaskCompleteDrawer = ({ task, taskType }) => {
  const [form] = Form.useForm();
  const [paySlipsDifference, setPaySlipsDifference] = useState(false);
  const [paySlipsValue, setPaySlipsValue] = useState(null);

  const contractPaySlips = 8; // Move this to a constant for easier reference

  const onFinish = (values) => {
    console.log('Form values:', values);
    // Here you would typically send the form data to your backend
  };

  const onPaySlipsChange = (value) => {
    setPaySlipsValue(value);
    setPaySlipsDifference(value !== null && value !== contractPaySlips);
  };

  const renderLonTaskFields = () => (
    <>
      <Form.Item name="paySlips" label="Number of Pay Slips Completed">
        <InputNumber onChange={onPaySlipsChange} min={0} />
      </Form.Item>
      <Alert
        message="Contract Information"
        description={`Number of pay slips in contract: ${contractPaySlips}`}
        type={paySlipsValue === contractPaySlips ? "success" : "info"}
        showIcon
        className="contract-alert"
      />
      {paySlipsValue === contractPaySlips && (
        <Alert
          message="Pay Slips Match Contract"
          description="The number of pay slips completed matches the contract."
          type="success"
          showIcon
          className="contract-alert"
        />
      )}
      {paySlipsDifference && (
        <Alert
          message="Pay Slip Difference Detected"
          description="The number of pay slips completed differs from the contract."
          type="warning"
          showIcon
          className="contract-alert"
        />
      )}
      {paySlipsDifference && (
        <Form.Item name="updateInvoice" valuePropName="checked">
          <Checkbox>Use this number for next invoice</Checkbox>
        </Form.Item>
      )}
      {paySlipsDifference && (
        <Form.Item name="updateContract" valuePropName="checked">
          <Checkbox>Update contract with the new number of pay slips as default going forward</Checkbox>
        </Form.Item>
      )}
    </>
  );

  return (
    <div className="task-complete-drawer">
      <h2>{`${taskType.charAt(0).toUpperCase() + taskType.slice(1)} Task Completed`}</h2>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="timeSpent" label="Time Spent (minutes)" rules={[{ required: true, message: 'Please input time spent' }]}>
          <InputNumber min={0} />
        </Form.Item>
        {taskType === 'lon' && renderLonTaskFields()}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TaskCompleteDrawer;