import React, { useState, useEffect } from 'react';
import { Table, Tag, Button, Modal, message, Tabs } from 'antd';
import { ScheduleOutlined, BugOutlined } from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import dayjs from 'dayjs';
import './AdminStatus.css';

const { TabPane } = Tabs;

function AdminStatus() {
  const [jobStatuses, setJobStatuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedJobLogs, setSelectedJobLogs] = useState('');

  useEffect(() => {
    fetchJobStatuses();
  }, []);

  const fetchJobStatuses = async () => {
    try {
      const response = await axios.get('/admin/job-status');
      setJobStatuses(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching job statuses:', error);
      message.error('Failed to fetch job statuses');
      setLoading(false);
    }
  };

  const showLogs = async (jobId) => {
    try {
      const response = await axios.get(`/admin/job-status/${jobId}`);
      setSelectedJobLogs(response.data.logs || 'No logs available');
      setModalVisible(true);
    } catch (error) {
      console.error('Error fetching job logs:', error);
      message.error('Failed to fetch job logs');
    }
  };

  const columns = [
    {
      title: 'Job Name',
      dataIndex: 'job_name',
      key: 'job_name',
      filters: [...new Set(jobStatuses.map(job => job.job_name))].map(name => ({ text: name, value: name })),
      onFilter: (value, record) => record.job_name.indexOf(value) === 0,
      sorter: (a, b) => a.job_name.localeCompare(b.job_name),
    },
    {
      title: 'Run Time',
      dataIndex: 'run_time',
      key: 'run_time',
      sorter: (a, b) => dayjs(a.run_time).unix() - dayjs(b.run_time).unix(),
      render: (run_time) => dayjs(run_time).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'success' ? 'green' : 'red'}>
          {status.toUpperCase()}
        </Tag>
      ),
      filters: [
        { text: 'Success', value: 'success' },
        { text: 'Failed', value: 'failed' },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button onClick={() => showLogs(record.id)}>View Logs</Button>
      ),
    },
  ];

  const ScheduleJobsContent = () => (
    <>
      <h3>Schedule/Jobs Status</h3>
      <Table
        columns={columns}
        dataSource={jobStatuses}
        rowKey="id"
        loading={loading}
      />
      <Modal
        title="Job Logs"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={800}
      >
        <pre>{selectedJobLogs}</pre>
      </Modal>
    </>
  );

  const ErrorLogsContent = () => (
    <>
      <h3>Error Logs</h3>
      <p>Error logs content will be added here.</p>
    </>
  );

  return (
    <div className="admin-status">
      <h2>Admin Status</h2>
      <Tabs tabPosition="left">
        <TabPane
          tab={
            <span>
              <ScheduleOutlined />
              Schedule/Jobs
            </span>
          }
          key="1"
        >
          <ScheduleJobsContent />
        </TabPane>
        <TabPane
          tab={
            <span>
              <BugOutlined />
              Error Logs
            </span>
          }
          key="2"
        >
          <ErrorLogsContent />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default AdminStatus;