import React, { useState, useEffect, useCallback } from 'react';
import { Button, Select, Typography, Space, Tooltip } from 'antd';
import { PlayCircleOutlined, PauseCircleOutlined, PlusOutlined, CloseOutlined, StopOutlined } from '@ant-design/icons';
import axios from '../api/axiosConfig';
import './TimeTracker.css';

const { Title, Text } = Typography;
const { Option } = Select;

function TimeTracker({ isVisible, isMaximized, isRunning, isLiveTimer, onOpen, onClose, onStatusChange, displayAccountProp, displayTaskProp, isLiveTimerVisible }) {
  const [time, setTime] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [otherTaskType, setOtherTaskType] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [displayAccount, setDisplayAccount] = useState('');
  const [displayTask, setDisplayTask] = useState('');

  useEffect(() => {
    fetchAccounts();
    fetchTasks();
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      const filtered = tasks.filter(task => task.account_id === selectedAccount);
      setFilteredTasks(filtered);
    } else {
      setFilteredTasks(tasks);
    }
  }, [selectedAccount, tasks]);

  useEffect(() => {
    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning]);

  const toggleTracker = () => {
    if (isMaximized) {
      onClose();
    } else {
      onOpen();
    }
  };

  const toggleTimer = () => {
    const newRunningState = !isRunning;
    onStatusChange(newRunningState, displayAccount, displayTask);
    if (newRunningState) {
      updateDisplayInfo();
    }
  };

  const stopTimer = () => {
    onStatusChange(false);
    setTime(0);
    setSelectedAccount(null);
    setSelectedTask(null);
    setOtherTaskType(null);
  };

  const resetTimer = () => {
    onStatusChange(false);
    setTime(0);
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const fetchAccounts = async () => {
    try {
      const response = await axios.get('/account/accounts');
      setAccounts(response.data);
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  const fetchTasks = async () => {
    try {
      const response = await axios.get('/account/user-tasks');
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const getAccountName = (id) => {
    const account = accounts.find(acc => acc.id === id);
    return account ? account.company_name : '';
  };

  const getTaskName = (taskId) => {
    if (taskId === 'other') return otherTaskType || 'Other Task';
    const task = tasks.find(t => t.id === taskId);
    return task ? task.text : '';
  };

  const updateDisplayInfo = useCallback(() => {
    setDisplayAccount(getAccountName(selectedAccount));
    setDisplayTask(getTaskName(selectedTask));
  }, [selectedAccount, selectedTask]);

  useEffect(() => {
    updateDisplayInfo();
  }, [selectedAccount, selectedTask, updateDisplayInfo]);

  useEffect(() => {
    setDisplayAccount(displayAccountProp);
    setDisplayTask(displayTaskProp);
  }, [displayAccountProp, displayTaskProp]);

  if (isLiveTimer) {
    return (
      <div className="live-timer-container">
        <div className="timer-info-minimized">
          <Text className="timer-display-minimized">{formatTime(time)}</Text>
          <div className="account-task-info">
            <Text className="account-name-minimized">{displayAccount}</Text>
            <Text className="task-name-minimized">{displayTask}</Text>
          </div>
        </div>
        <Button
          className="timer-control-button pause-button"
          icon={isRunning ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
          onClick={toggleTimer}
        />
        <Button
          className="timer-control-button stop-button"
          icon={<StopOutlined />}
          onClick={stopTimer}
        />
      </div>
    );
  }

  return (
    <div className={`time-tracker-container ${isVisible ? 'visible' : ''} ${isMaximized ? 'maximized' : ''}`}>
      {!isMaximized && (
        <div className="time-tracker-minimized">
          <Tooltip title="Time Tracker" placement="topRight">
            <Button
              className={`time-tracker-button ${isRunning || isLiveTimerVisible ? 'running' : ''}`}
              type="primary"
              icon={<PlayCircleOutlined />}
              onClick={toggleTracker}
            />
          </Tooltip>
        </div>
      )}
      {isMaximized && (
        <div className="time-tracker-card">
          <div className="time-tracker-header">
            <Title level={4}>Time Tracker</Title>
            <Button 
              icon={<CloseOutlined />} 
              onClick={toggleTracker}
              type="text"
            />
          </div>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Text className="timer-display">{formatTime(time)}</Text>
            <Space>
              <Button
                type="primary"
                icon={isRunning ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                onClick={toggleTimer}
              >
                {isRunning ? 'Pause' : 'Start'}
              </Button>
              <Button
                icon={<PlusOutlined />}
                onClick={resetTimer}
              >
                Reset
              </Button>
            </Space>
            <Select
              placeholder="Select Account"
              showSearch
              style={{ width: '100%' }}
              onChange={(value) => setSelectedAccount(value)}
              value={selectedAccount}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {accounts.map(account => (
                <Option key={account.id} value={account.id}>{account.company_name}</Option>
              ))}
            </Select>
            <Select
              placeholder="Select Task"
              style={{ width: '100%' }}
              onChange={(value) => setSelectedTask(value)}
              value={selectedTask}
            >
              {filteredTasks.map(task => (
                <Option key={task.id} value={task.id}>{task.text}</Option>
              ))}
            </Select>
            {selectedTask === 'other' && (
              <Select
                placeholder="Select Other Task Type"
                style={{ width: '100%', marginTop: '10px' }}
                onChange={(value) => setOtherTaskType(value)}
                value={otherTaskType}
              >
                <Option value="consulting">Consulting</Option>
                <Option value="phone">Phone Call</Option>
              </Select>
            )}
          </Space>
        </div>
      )}
    </div>
  );
}

export default TimeTracker;