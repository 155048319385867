import React from 'react';
import { Tag, Avatar, Button, Tooltip } from 'antd';
import dayjs from 'dayjs';
import TaskBoard from './TaskBoards';
import { PlayCircleOutlined, PauseCircleOutlined, StopOutlined } from '@ant-design/icons';
import { dealDataConfig } from '../configs/DealDataConfig';

const ActivitiesTasks = () => {
  const boardStatusOptions = [
    { value: 'Not started', color: '#8c8c8c' },
    { value: 'In progress', color: '#1890ff' },
    { value: 'Completed', color: '#52c41a' },
  ];

  const columns = [
    {
      title: 'Deal',
      dataIndex: 'deal_name',
      key: 'deal_name',
      sorter: (a, b) => a.deal_name.localeCompare(b.deal_name),
      fixed: 'left',
    },
    {
      title: 'Task',
      dataIndex: 'description',
      key: 'description',
      render: (text) => text,
      fixed: 'left',
    },
    {
      title: 'Assignee',
      dataIndex: 'assigned_users',
      key: 'assigned_users',
      render: assignees => (
        <Avatar.Group>
          {(assignees || []).map(assignee => (
            <Tooltip key={assignee.id} title={assignee.full_name}>
              <Avatar
                style={{ backgroundColor: assignee.avatar_color }}
              >
                {assignee.initials}
              </Avatar>
            </Tooltip>
          ))}
        </Avatar.Group>
      ),
    },
    {
      title: 'Work Date',
      dataIndex: 'work_date',
      key: 'work_date',
      render: (date) => dayjs(date).format('YYYY-MM-DD'),
    },
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'due_date',
      render: (date) => dayjs(date).format('YYYY-MM-DD'),
    },
    {
      title: 'Time Tracker',
      dataIndex: 'worktime',
      key: 'worktime',
      render: (worktime) => {
        const totalMinutes = worktime.reduce((acc, entry) => acc + entry.duration, 0);
        return (
          <div className="time-tracker-container">
            <span>{`${totalMinutes} min`}</span>
            <div className="time-tracker-buttons">
              <Button icon={<PlayCircleOutlined />} />
              <Button icon={<PauseCircleOutlined />} />
              <Button icon={<StopOutlined />} />
            </div>
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={boardStatusOptions.find(o => o.value === status)?.color || 'default'}>
          {status}
        </Tag>
      ),
    },
  ];

  const cardConfig = {
    showAssignee: true,
    showDates: true
  };

  const relevantDealDataConfig = dealDataConfig.filter(item => 
    ['data.contact_name', 'data.company_name'].includes(item.key)
  );

  return (
    <TaskBoard
      title="Activities Tasks"
      fetchUrl="/taskboard/tasks/onboarding/activity"
      defaultView="table"
      columns={columns}
      boardStatusOptions={boardStatusOptions}
      cardConfig={cardConfig}
      dealDataConfig={relevantDealDataConfig}
      taskType="activities"
    />
  );
};

export default ActivitiesTasks;