import React, { useState } from 'react';
import { Card, Table, Button } from 'antd';
import { UserOutlined, ClockCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import './OffboardingDashboard.css';
import ChurnModal from './ChurnModal';

const OffboardingDashboard = () => {
  const [newChurnedCustomer, setNewChurnedCustomer] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const dummyOffboardings = [
    { id: 1, customerName: 'Acme Corp', status: 'In Progress', startDate: '2023-05-01' },
    { id: 2, customerName: 'TechSolutions Inc', status: 'Completed', startDate: '2023-04-28' },
    { id: 3, customerName: 'Global Enterprises', status: 'Pending', startDate: '2023-05-03' },
  ];

  const columns = [
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
    },
  ];

  const handleAddChurnedCustomer = () => {
    console.log('New churned customer:', newChurnedCustomer);
    // Here you would typically add the new churned customer to your state or send it to an API
    setNewChurnedCustomer('');
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleChurnSubmit = (churnData) => {
    console.log('Churn data:', churnData);
    // Here you would typically send the churn data to your API
    setIsModalVisible(false);
  };

  return (
    <div className="offboarding-dashboard">
      <h1>Offboarding Dashboard</h1>
      <div className="offboarding-dashboard-cards">
        <Card className="dashboard-card">
          <UserOutlined className="card-icon" />
          <div className="card-content">
            <h2>12</h2>
          </div>
          <p>Active Offboardings</p>
        </Card>
        <Card className="dashboard-card">
          <ClockCircleOutlined className="card-icon" />
          <div className="card-content">
            <h2>5</h2>
          </div>
          <p>Pending Offboardings</p>
        </Card>
        <Card className="dashboard-card">
          <CheckCircleOutlined className="card-icon" />
          <div className="card-content">
            <h2>87</h2>
          </div>
          <p>Completed Offboardings</p>
        </Card>
      </div>

      <div className="churned-customers-input">
        <Button type="primary" onClick={showModal}>
          Add Churned Customer
        </Button>
      </div>

      <ChurnModal
        visible={isModalVisible}
        onClose={handleModalClose}
        onSubmit={handleChurnSubmit}
      />

      <div className="latest-offboardings">
        <h2>Latest Offboardings</h2>
        <Table 
          columns={columns} 
          dataSource={dummyOffboardings} 
          rowKey="id"
        />
      </div>
    </div>
  );
};

export default OffboardingDashboard;