import React from 'react';
import { Tag, Avatar, Button, Tooltip } from 'antd';
import dayjs from 'dayjs';
import TaskBoard from './TaskBoards';
import { PlayCircleOutlined, PauseCircleOutlined, StopOutlined } from '@ant-design/icons';
import { dealDataConfig } from '../configs/DealDataConfig';

const LonTasks = () => {
  const boardStatusOptions = [
    { value: 'Blank', color: '#8c8c8c' },
    { value: 'Lønkunder', color: '#1890ff' },
    { value: 'Indhent timer', color: '#ffa940' },
    { value: 'Timer indsendt', color: '#722ed1' },
    { value: 'Tag kontakt til kunden', color: '#f5222d' },
    { value: 'Løn lavet', color: '#52c41a' },
  ];

  const mapFrequencyToLabel = (frequency) => {
    if (!frequency || !frequency.freq) return 'N/A';
    const mapping = {
      DAILY: 'Daily',
      WEEKLY: 'Weekly',
      MONTHLY: 'Monthly',
      QUARTERLY: 'Quarterly',
      YEARLY: 'Yearly',
    };
    return mapping[frequency.freq.toUpperCase()] || frequency.freq;
  };

  const columns = [
    {
      title: 'Deal',
      dataIndex: 'deal_name',
      key: 'deal_name',
      sorter: (a, b) => a.deal_name.localeCompare(b.deal_name),
      fixed: 'left',
    },
    {
      title: 'Task',
      dataIndex: 'description',
      key: 'description',
      render: (text) => text,
      fixed: 'left',
    },
    {
      title: 'Assignee',
      dataIndex: 'assignees',
      key: 'assignees',
      render: assignees => (
        <Avatar.Group>
          {assignees.map(assignee => (
            <Tooltip key={assignee.id} title={assignee.full_name}>
              <Avatar
                style={{ backgroundColor: assignee.avatar_color }}
              >
                {assignee.initials}
              </Avatar>
            </Tooltip>
          ))}
        </Avatar.Group>
      ),
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',
      render: (frequency) => mapFrequencyToLabel(frequency),
    },
    {
      title: 'Next Work Date',
      dataIndex: 'next_work_date',
      key: 'next_work_date',
      render: (date) => dayjs(date).format('YYYY-MM-DD'),
    },
    {
      title: 'Next Due Date',
      dataIndex: 'next_due_date',
      key: 'next_due_date',
      render: (date) => dayjs(date).format('YYYY-MM-DD'),
    },
    {
      title: 'Time Tracker',
      dataIndex: 'time_tracker',
      key: 'time_tracker',
      render: (time) => (
        <div className="time-tracker-container">
          <span>{`${time} min`}</span>
          <div className="time-tracker-buttons">
            <Button icon={<PlayCircleOutlined />} />
            <Button icon={<PauseCircleOutlined />} />
            <Button icon={<StopOutlined />} />
          </div>
        </div>
      ),
    },
    {
      title: 'Expected Workload',
      dataIndex: 'expected_workload',
      key: 'expected_workload',
      render: (workload) => `${workload} min`,
    },
    {
      title: 'Status',
      dataIndex: 'board_status',
      key: 'board_status',
      render: (status) => (
        <Tag color={boardStatusOptions.find(o => o.value === status)?.color || 'default'}>
          {status}
        </Tag>
      ),
    },
    {
      title: 'Progress',
      dataIndex: 'progress_status',
      key: 'progress_status',
      render: (status) => {
        const color = status === 'Completed' ? 'green' : status === 'In progress' ? 'orange' : 'red';
        return <Tag color={color}>{status}</Tag>;
      },
    },
  ];

  const cardConfig = {
    showAssignee: true,
    showFrequency: true
  };

  const relevantDealDataConfig = dealDataConfig.filter(item => 
    ['data.lønfrekvens', 'data.antal_medarbejdere_til_lønhandtering'].includes(item.key)
  );

  return (
    <TaskBoard
      title="Løn Tasks"
      fetchUrl="/taskboard/tasks/lon"
      defaultView="kanban"
      columns={columns}
      boardStatusOptions={boardStatusOptions}
      cardConfig={cardConfig}
      dealDataConfig={relevantDealDataConfig}
      taskType="lon"
    />
  );
};


export default LonTasks;