import React from 'react';
import { Layout, Tabs } from 'antd';
import { BarChartOutlined, ScheduleOutlined, UnorderedListOutlined, SettingOutlined } from '@ant-design/icons';
import ErpHeader from '../components/ErpHeader';
import AdminStatus from './pages/AdminStatus';
import AdminSchedule from './pages/AdminSchedule';
import AdminTasks from './pages/AdminTasks';
import './AdminDashboard.css';

const { Content } = Layout;

function AdminDashboard({ theme, toggleTheme }) {
  const items = [
    {
      key: 'status',
      label: (
        <span>
          <BarChartOutlined />
          Status
        </span>
      ),
      children: <AdminStatus />,
    },
    {
      key: 'schedule',
      label: (
        <span>
          <ScheduleOutlined />
          Schedule
        </span>
      ),
      children: <AdminSchedule />,
    },
    {
      key: 'tasks',
      label: (
        <span>
          <UnorderedListOutlined />
          Tasks and Workload
        </span>
      ),
      children: <AdminTasks />,
    },
  ];

  return (
    <Layout className="admin-dashboard-layout">
      <ErpHeader 
        theme={theme} 
        toggleTheme={toggleTheme} 
        icon={SettingOutlined}
        title="Admin"
      />
      <Content className="admin-dashboard-content">
        <Tabs defaultActiveKey="status" type="card" className="admin-tabs" items={items} />
      </Content>
    </Layout>
  );
}

export default AdminDashboard;
