import React from 'react';
import { Modal, List, Avatar, Typography, Divider } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import CustomScrollbar from '../components/CustomScrollbar';
import './ActivityScreen.css';

//NOTE: Add "Last online" to the user list with some kind of useful format

dayjs.extend(utc);
dayjs.extend(timezone);

const { Text } = Typography;

function ActivityScreen({ visible, onClose, users, currentUser }) {

  const getInitials = (name, surname) => {
    if (!name && !surname) return '?';
    return `${name ? name[0] : ''}${surname ? surname[0] : ''}`.toUpperCase();
  };

  const getDisplayName = (user) => {
    return `${user.name || ''} ${user.surname || ''}`.trim() || user.email || 'Unknown User';
  };

  const getLocalTime = (locationInfo) => {
    if (!locationInfo || !locationInfo.timezone) return 'Unknown';
    try {
      return dayjs().tz(locationInfo.timezone).format('HH:mm');
    } catch (error) {
      console.error('Error formatting time:', error);
      return 'Unknown';
    }
  };

  const getLocation = (locationInfo) => {
    if (!locationInfo) return 'Unknown';
    return `${locationInfo.city}, ${locationInfo.country}`;
  };

  const onlineUsers = users.filter(user => user.status === 'online');
  const inactiveUsers = users.filter(user => user.status === 'inactive');
  const offlineUsers = users.filter(user => user.status === 'offline');

  const renderUserList = (userList, status) => (
    <List
      itemLayout="horizontal"
      dataSource={userList}
      renderItem={user => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar
                style={{ backgroundColor: user.settings?.avatar_color || '#f56a00' }}
              >
                {getInitials(user.name, user.surname)}
              </Avatar>
            }
            title={getDisplayName(user)}
            description={
              <>
                <Text>{user.email}</Text>
                <br />
                <Text>{getLocation(user.location_info)}</Text>
                <br />
                <Text>Local time: {getLocalTime(user.location_info)}</Text>
                {status === 'online' && <span className="online-indicator">● Online</span>}
                {status === 'inactive' && <span className="inactive-indicator">● Inactive</span>}
                {status === 'offline' && <span className="offline-indicator">● Offline</span>}
              </>
            }
          />
        </List.Item>
      )}
    />
  );

  return (
    <Modal
      title="Team Members"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={600}
    >
      <CustomScrollbar className="activity-screen-scrollbar">
        <div style={{ padding: '24px' }}>
          <Divider orientation="left">Online Users</Divider>
          {renderUserList(onlineUsers, 'online')}
          <Divider orientation="left">Inactive Users</Divider>
          {renderUserList(inactiveUsers, 'inactive')}
          <Divider orientation="left">Offline Users</Divider>
          {renderUserList(offlineUsers, 'offline')}
        </div>
      </CustomScrollbar>
    </Modal>
  );
}

export default ActivityScreen;