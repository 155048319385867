import React, { useEffect, useState } from 'react';
import { Modal, Tabs, Button, Descriptions, Spin, Input, Select, Switch, DatePicker, Tag } from 'antd';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ArrowUpOutlined, ArrowDownOutlined, MailOutlined, SyncOutlined, FileTextOutlined, UserOutlined, ShopOutlined, ClockCircleOutlined, CalendarOutlined, FieldTimeOutlined, PlayCircleOutlined } from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import './TaskView.css';
import { dealDataConfig } from '../../configs/DealDataConfig';
import TaskViewCommunication from '../components/TaskViewCommunication';
import TaskViewUpdates from '../components/TaskViewUpdates';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import CustomScrollbar from '../../components/CustomScrollbar';

dayjs.extend(utc);
dayjs.extend(timezone);

const { TabPane } = Tabs;

const TaskView = ({ tasks: propTasks, taskType: propTaskType, boardStatusOptions }) => {
  const { taskId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('1');
  const [showAccount, setShowAccount] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [accountData, setAccountData] = useState(null);
  const [productsData, setProductsData] = useState(null);
  const [showAllDealData, setShowAllDealData] = useState(false);

  // Use prop values if available, otherwise extract from location state or URL
  const taskType = propTaskType || location.state?.taskType || location.pathname.split('/')[2];
  const tasks = propTasks || location.state?.tasks || [];

  useEffect(() => {
    fetchTaskDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId, taskType]);

  const fetchTaskDetails = async () => {
    try {
      const response = await axios.get(`/taskboard/tasks/${taskType}/${taskId}`);
      setTask(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching task details:', error);
      setLoading(false);
    }
  };

  const handleClose = () => {
    const newPath = location.pathname.split('/').slice(0, -1).join('/');
    navigate(newPath);
  };

  const handlePrevTask = () => {
    const currentIndex = tasks.findIndex(t => t.id.toString() === taskId);
    if (currentIndex > 0) {
      navigate(`/boards/${taskType}/${tasks[currentIndex - 1].id}`, { state: { tasks, taskType } });
    }
  };

  const handleNextTask = () => {
    const currentIndex = tasks.findIndex(t => t.id.toString() === taskId);
    if (currentIndex < tasks.length - 1) {
      navigate(`/boards/${taskType}/${tasks[currentIndex + 1].id}`, { state: { tasks, taskType } });
    }
  };

  const fetchAccountData = async () => {
    try {
      const response = await axios.get(`/taskboard/tasks/${taskType}/${taskId}/account`);
      setAccountData(response.data);
    } catch (error) {
      console.error('Error fetching account data:', error);
    }
  };

  const fetchProductsData = async () => {
    try {
      const response = await axios.get(`/taskboard/tasks/${taskType}/${taskId}/products`);
      setProductsData(response.data);
    } catch (error) {
      console.error('Error fetching products data:', error);
    }
  };

  const handleAccountClick = () => {
    if (!accountData) {
      fetchAccountData();
    }
    setShowAccount(!showAccount);
    setShowProducts(false);
  };

  const handleProductsClick = () => {
    if (!productsData) {
      fetchProductsData();
    }
    setShowProducts(!showProducts);
    setShowAccount(false);
  };

  const handleShowAllDealData = () => {
    setShowAllDealData(!showAllDealData);
  };

  const handleDealDataUpdate = (key, value) => {
    // Implement the logic to update the deal data
    console.log(`Updating ${key} to ${value}`);
    // You might want to call an API to update the data on the server
    // and then update the local state
    setTask(prevTask => ({
      ...prevTask,
      deal: {
        ...prevTask.deal,
        [key]: value
      }
    }));
  };

  const renderTaskStatus = (status) => {
    const statusOption = boardStatusOptions.find(option => option.value === status);
    return (
      <Tag color={statusOption?.color || 'default'} className="task-status-tag">
        {status}
      </Tag>
    );
  };

  const formatDate = (dateString) => {
    return dayjs(dateString).format('YYYY-MM-DD');
  };

  const handleDateChange = (dateType, date) => {
    const formattedDate = formatDate(date);
    setTask(prevTask => ({
      ...prevTask,
      [dateType]: formattedDate
    }));
    // Here you would also make an API call to update the date on the server
    // For example:
    // axios.put(`/taskboard/tasks/${taskType}/${taskId}`, { [dateType]: formattedDate });
  };

  if (loading) {
    return <Spin size="large" />;
  }

  if (!task) return null;

  return (
    <Modal
      open={true}
      onCancel={handleClose}
      footer={null}
      width="90%"
      className="task-view-modal"
      style={{ top: '5%' }}
    >
      <div className="task-view">
        <div className="task-view-header">
          <div className="task-navigation">
            <Button
              icon={<ArrowUpOutlined />}
              onClick={handlePrevTask}
              disabled={tasks.findIndex(t => t.id.toString() === taskId) === 0}
              className="nav-button"
            />
            <Button
              icon={<ArrowDownOutlined />}
              onClick={handleNextTask}
              disabled={tasks.findIndex(t => t.id.toString() === taskId) === tasks.length - 1}
              className="nav-button"
            />
          </div>
          <h2 className="task-title">{task.deal?.name}: {task.description}</h2>
          <Tabs activeKey={activeTab} onChange={setActiveTab} className="task-tabs">
            <TabPane tab={<span><MailOutlined /> Communication</span>} key="1" />
            <TabPane tab={<span><SyncOutlined /> Updates / Notes</span>} key="2" />
            <TabPane tab={<span><FileTextOutlined /> Activity Log</span>} key="3" />
          </Tabs>
        </div>
        <div className="task-view-content">
          <CustomScrollbar className="task-view-main">
            <div className="tab-content">
              {activeTab === '1' && (
                <TaskViewCommunication 
                  dealId={task?.deal_id} 
                  contact={task?.contact}
                />
              )}
              {activeTab === '2' && (
                <TaskViewUpdates />
              )}
              {activeTab === '3' && (
                /* Implement activity log content */
                <div>Activity log content goes here</div>
              )}
            </div>
          </CustomScrollbar>
          <CustomScrollbar className="task-view-sidebar">
            <div className="sidebar-content">
              <div className="task-data">
                <h3>Task</h3>
                <div className="task-info-container">
                  <div className="task-dates">
                    <div className="taskview-date">
                      <ClockCircleOutlined className="task-icon" />
                      <DatePicker
                        value={dayjs(formatDate(task.next_work_date))}
                        format="YYYY-MM-DD"
                        className="task-datepicker"
                        suffixIcon={null}
                        onChange={(date) => handleDateChange('next_work_date', date)}
                      />
                      <span className="task-date-label">Work Date</span>
                    </div>
                    <div className="taskview-date">
                      <CalendarOutlined className="task-icon" />
                      <DatePicker
                        value={dayjs(formatDate(task.next_due_date))}
                        format="YYYY-MM-DD"
                        className="task-datepicker"
                        suffixIcon={null}
                        onChange={(date) => handleDateChange('next_due_date', date)}
                      />
                      <span className="task-date-label">Due Date</span>
                    </div>
                  </div>
                  <div className="task-status-workload">
                    <div className="task-status">
                      {renderTaskStatus(task.board_status)}
                    </div>
                    <div className="task-workload">
                      <FieldTimeOutlined className="task-icon" />
                      <span>{task.expected_workload} min</span>
                    </div>
                  </div>
                  <Button
                    icon={<PlayCircleOutlined />}
                    type="primary"
                    className="start-timer-button"
                  >
                    Start Timer
                  </Button>
                </div>
              </div>
              <div className="contact-data">
                <h3>Contact</h3>
                <Descriptions column={1}>
                  <Descriptions.Item label="Name">{task.contact?.name}</Descriptions.Item>
                  <Descriptions.Item label="Email">{task.contact?.email}</Descriptions.Item>
                  <Descriptions.Item label="Phone">{task.contact?.phone}</Descriptions.Item>
                </Descriptions>
              </div>
              <div className="deal-data">
                <h3>Deal</h3>
                <Descriptions column={1}>
                  <Descriptions.Item label="Deal">{task.deal?.name}</Descriptions.Item>
                  <Descriptions.Item label="CVR">{task.deal?.cvr}</Descriptions.Item>
                  <Descriptions.Item label="Address">{task.deal?.address} {task.deal?.zipcode} {task.deal?.city}</Descriptions.Item>
                  <Descriptions.Item label="Customer Number">{task.deal?.economic_customer_number}</Descriptions.Item>
                  <Descriptions.Item label="Onboard Status">{task.deal?.onboard_status}</Descriptions.Item>
                </Descriptions>
                <Button onClick={handleShowAllDealData}>
                  {showAllDealData ? 'Hide Deal Data' : 'Show All Deal Data'}
                </Button>
                {showAllDealData && <DealDataDetails deal={task.deal} onUpdate={handleDealDataUpdate} />}
              </div>
              <div className="account-products">
                <Button icon={<UserOutlined />} onClick={handleAccountClick}>Account</Button>
                <Button icon={<ShopOutlined />} onClick={handleProductsClick}>Products</Button>
                {showAccount && accountData && (
                  <div className="account-data">
                    <h3>Account Details</h3>
                    <Descriptions column={1}>
                      <Descriptions.Item label="Company Name">{accountData.company_name}</Descriptions.Item>
                      <Descriptions.Item label="CVR">{accountData.cvr}</Descriptions.Item>
                      <Descriptions.Item label="Address">{accountData.address}</Descriptions.Item>
                      <Descriptions.Item label="Zipcode">{accountData.zipcode}</Descriptions.Item>
                      <Descriptions.Item label="City">{accountData.city}</Descriptions.Item>
                    </Descriptions>
                  </div>
                )}
                {showProducts && productsData && (
                  <div className="products-data">
                    <h3>Products</h3>
                    {productsData.map(product => (
                      <Descriptions key={product.id} column={1} bordered>
                        <Descriptions.Item label="Name">{product.name}</Descriptions.Item>
                        <Descriptions.Item label="Code">{product.code}</Descriptions.Item>
                        <Descriptions.Item label="Quantity">{product.quantity}</Descriptions.Item>
                        <Descriptions.Item label="Price">{product.item_price}</Descriptions.Item>
                        <Descriptions.Item label="Sum">{product.sum}</Descriptions.Item>
                        <Descriptions.Item label="Billing Start">{formatDate(product.billing_start_date)}</Descriptions.Item>
                        <Descriptions.Item label="Billing Frequency">{product.billing_frequency}</Descriptions.Item>
                      </Descriptions>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </CustomScrollbar>
        </div>
      </div>
    </Modal>
  );
};

const DealDataDetails = ({ deal, onUpdate }) => {
  const [editingField, setEditingField] = useState(null);

  if (!deal) return null;

  const handleEdit = (key, value) => {
    setEditingField({ key, value });
  };

  const handleSave = () => {
    if (editingField) {
      onUpdate(editingField.key, editingField.value);
      setEditingField(null);
    }
  };

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : undefined;
    }, obj);
  };

  const renderEditableField = (fieldConfig, value) => {
    if (!fieldConfig.editable) {
      return value?.toString() || 'N/A';
    }

    if (editingField && editingField.key === fieldConfig.key) {
      switch (fieldConfig.type) {
        case 'text':
        case 'number':
          return (
            <Input
              type={fieldConfig.type}
              value={editingField.value}
              onChange={(e) => setEditingField({ ...editingField, value: e.target.value })}
              onPressEnter={handleSave}
              onBlur={handleSave}
            />
          );
        case 'dropdown':
          return (
            <Select
              value={editingField.value}
              onChange={(value) => {
                setEditingField({ ...editingField, value });
                onUpdate(fieldConfig.key, value);
              }}
              style={{ width: '100%' }}
            >
              {fieldConfig.options.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          );
        case 'boolean':
          return (
            <Switch
              checked={editingField.value}
              onChange={(checked) => {
                setEditingField({ ...editingField, value: checked });
                onUpdate(fieldConfig.key, checked);
              }}
            />
          );
        case 'date':
          return (
            <DatePicker
              value={editingField.value ? dayjs(editingField.value) : null}
              onChange={(date, dateString) => {
                setEditingField({ ...editingField, value: dateString });
                onUpdate(fieldConfig.key, dateString);
              }}
            />
          );
        default:
          return value?.toString() || 'N/A';
      }
    }

    return (
      <span
        className="editable-field"
        onClick={() => handleEdit(fieldConfig.key, value)}
      >
        {fieldConfig.type === 'dropdown' && fieldConfig.options ? (
          fieldConfig.options.find(opt => opt.value === value)?.label || value
        ) : fieldConfig.type === 'boolean' ? (
          <Switch checked={value} disabled />
        ) : fieldConfig.type === 'date' ? (
          dayjs(value).format('YYYY-MM-DD')
        ) : (
          value?.toString() || 'N/A'
        )}
      </span>
    );
  };

  const renderDealData = () => {
    return dealDataConfig.map(fieldConfig => {
      const value = getNestedValue(deal, fieldConfig.key);
      
      if (value === undefined) return null;

      return (
        <Descriptions.Item
          key={fieldConfig.key}
          label={fieldConfig.label}
          className="deal-data-item"
        >
          {renderEditableField(fieldConfig, value)}
        </Descriptions.Item>
      );
    }).filter(Boolean);
  };

  return (
    <CustomScrollbar className="deal-data-details">
      <Descriptions column={1} bordered>
        {renderDealData()}
      </Descriptions>
    </CustomScrollbar>
  );
};

export default TaskView;