import React, { useState } from 'react';
import { Layout } from 'antd';
import { Routes, Route, useLocation, useSearchParams } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Home from './Home';
import Work from './Work';
import Dashboard from './Dashboard';
import Deals from '../customers/Deals';
import Accounts from '../customers/Accounts';
import Contacts from '../customers/Contacts';
import Products from '../customers/Products';
import OnboardingDashboard from '../onboarding/OnboardingDashboard';
import OnboardingCases from '../onboarding/OnboardingCases';
import OnboardingDetails from '../onboarding/OnboardingDetails';
import CustomerOnboardingForm from '../onboarding/forms/CustomerOnboardingForm';
import OffboardingDashboard from '../offboarding/OffboardingDashboard';

// Taskboards
import BogforingTasks from '../taskboards/BogforingTasks';
import LonTasks from '../taskboards/LonTasks';
import MomsTasks from '../taskboards/MomsTasks';
import ArsafslutningTasks from '../taskboards/ArsafslutningTasks';
import AndreOpgaverTasks from '../taskboards/AndreOpgaverTasks';
import ActivitiesTasks from '../taskboards/ActivitiesTasks';

import './Pages.css';
import CustomScrollbar from '../components/CustomScrollbar';

const { Content } = Layout;

function Pages({ theme, toggleTheme, user }) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [collapsed, setCollapsed] = useState(false);
  const isOnboardingForm = location.pathname.includes('/onboarding/customer-onboarding-form');

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header 
        theme={theme} 
        toggleTheme={toggleTheme} 
        toggleSidebar={toggleCollapsed}
        collapsed={collapsed}
        user={user}
      />
      <Layout hasSider>
        {!isOnboardingForm && (
          <Sidebar 
            collapsed={collapsed} 
            toggleCollapsed={toggleCollapsed} 
            theme={theme} 
            user={user}
          />
        )}
        <Layout className="site-layout">
          <Content
            className={`site-layout-background ${isOnboardingForm ? 'full-width' : ''}`}
            style={{
              padding: 20,
              minHeight: 280,
            }}
          >
            <Routes>
              <Route path="/home" element={<Home user={user} />} />
              <Route path="/work" element={<Work />} />
              <Route path="/dashboard" element={<Dashboard />} />
              
              <Route path="/customers/deals" element={<Deals />} />
              <Route path="/customers/accounts" element={<Accounts />} />
              <Route path="/customers/contacts" element={<Contacts />} />
              <Route path="/customers/products" element={<Products />} />

              <Route path="/onboarding" element={<OnboardingDashboard />} />
              <Route path="/onboarding/cases" element={<OnboardingCases initialFilter={searchParams.get('filter')} />} />
              <Route path="/onboarding/cases/:id" element={<OnboardingDetails />} />
              <Route path="/onboarding/customer-onboarding-form/:onboardingId" element={<CustomerOnboardingForm />} />

              <Route path="/offboarding" element={<OffboardingDashboard />} />

              {/* Use wildcard routes for taskboards */}
              <Route path="/boards/bogforing/*" element={<BogforingTasks />} />
              <Route path="/boards/lon/*" element={<LonTasks />} />
              <Route path="/boards/moms/*" element={<MomsTasks />} />
              <Route path="/boards/arsafslutning/*" element={<ArsafslutningTasks />} />
              <Route path="/boards/andre-opgaver/*" element={<AndreOpgaverTasks />} />
              <Route path="/boards/activities/*" element={<ActivitiesTasks />} />
              
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Pages;